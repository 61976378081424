<template>
	<div class="nb-home-page-container">
		<SupportComponent></SupportComponent>
		<ModalContainer modalTitle="登录/注册" @close="onRegisterModalClose" v-if="isRegisterModalOpen === true">
			<RegisterModalContent @registerSuccess="onRegisterAccountSuccess"
			                      @loginSuccess="onLoginSuccess"></RegisterModalContent>
		</ModalContainer>
		<ModalContainer modalTitle="账户信息" @close="onAccountInfoClose" v-if="isAccountInfoModalOpen === true">
			<UserInfoContent @userLogout="onUserLogout"/>
		</ModalContainer>
		<ModalContainer modalTitle="选择支付方式" @close="onPaymentMethodModalClose"
		                v-if="isPaymentMethodModalOpen === true">
			<PaymentMethodsContent :selectedPlanType="this.selectedPlanType"/>
		</ModalContainer>
		<ModalContainer modalTitle="" :showClose="false" v-if="showConfirmDialog === true">
			<div class="nb-confirm-btn-container">
				<div class="nb-confirm-dialog-text">{{ this.confirmModalTitle }}</div>
				<div class="nb-confirm-btn-row">
					<div class="nb-confirm-btn" @click="onConfirmBtnClick">{{ $t("确认") }}</div>
					<div class="nb-cancel-btn" @click="onCancelBtnClick">{{ $t("取消") }}</div>
				</div>

			</div>
		</ModalContainer>
		<PageHeader></PageHeader>
		<!--		<MainView></MainView>-->
		<router-view></router-view>
		<FooterComponent/>
	</div>

</template>

<script>
import {
	getAndroidDownloadLink,
	getWindowsDownloadLink,
	hideLoading,
	isSessionValid,
	showLoading,
	showToast,
	startDownload,
	getPlanInfo,
	reportVisit,
	detectOS,
	getR2MacosDownloadLink
} from "@/utils/methods";

import {userList} from "@/utils/constants";

import {Swiper, SwiperSlide} from 'swiper/vue';
import {A11y, Autoplay, Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import ModalContainer from "@/components/ModalContainer";
import RegisterModalContent from "@/components/RegisterModalContent";
import UserInfoContent from "@/components/UserInfoContent";
import PaymentMethodsContent from "@/components/PaymentMethodsContent";
import PageHeader from "@/components/PageHeader.vue";
import SupportComponent from "@/components/SupportComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import MainView from "@/Pages/SubPages/MainView.vue";

export default {
	name: "HomePage",
	components: {
		// Swiper,
		// SwiperSlide,
		ModalContainer,
		RegisterModalContent,
		UserInfoContent,
		PaymentMethodsContent,
		PageHeader,
		// MainView,
		SupportComponent,
		FooterComponent
	},
	data() {
		return {
			currentComment: '',
			sliderPerView: 5,
			isMobileMenuOpen: false,
			isRegisterModalOpen: false,
			isAccountInfoModalOpen: false,
			isPaymentMethodModalOpen: false,
			confirmModalTitle: '',
			confirmCallback: null,
			showConfirmDialog: false,
			planInfoList: [],
			selectedPlanType: null,
			openWindowsDownloadModal: false,
			os: null,
			isDownloading: false
		};
	},
	setup() {
		return {
			userList,
			modules: [Navigation, Pagination, Autoplay, A11y],
		};
	},
	async mounted() {
		this.os = detectOS()
		this.currentComment = this.userList[0].comment
		if (window.innerWidth < 900) {
			this.sliderPerView = 3
		} else {
			this.sliderPerView = 5
		}
		window.addEventListener('resize', () => {
			if (window.innerWidth < 900) {
				this.sliderPerView = 3
			} else {
				this.sliderPerView = 5
			}

			// 假设你的div元素有一个ID为'my-div'
			// const nbBannerContainer = document.getElementById('nb_banner_container');

// 获取div的宽度
//       const width = nbBannerContainer.clientWidth;

// 设置div的高度与宽度相同
//       nbBannerContainer.style.height = width / 15 * 8 + 'px';
		});
		const result = await isSessionValid()
		if (result.err === false) {
			this.$store.dispatch('setLoginState', {isLogin: true, sessionExpireTimestamp: result.expiredAtTimestamp})
			this.$store.dispatch('setUserInfo', {email: result.email, planExpireTime: result.planExpireTime})
		}
		const planInfoResult = await getPlanInfo()
		if (planInfoResult.code === 200) {
			this.planInfoList = planInfoResult.msg?.planInfo
		}

		// 假设你的div元素有一个ID为'my-div'
		// const nbBannerContainer = document.getElementById('nb_banner_container');

// 获取div的宽度
//     const width = nbBannerContainer.clientWidth;

// 设置div的高度与宽度相同
//     nbBannerContainer.style.height = width / 15 * 8 + 'px';

		await reportVisit()
	},
	computed: {
		isUserLogin() {
			return this.$store.state.isLogin
		}
	},
	methods: {
		onWindowsDownloadModalClose() {
			this.openWindowsDownloadModal = false
		},
		getPriceCardActionText(item) {
			return '立即购买'
			// if (this.isUserLogin === true) {
			// 	return '立即购买'
			// }
			// if (item.promoCode) {
			// 	return '登录领取'
			// }
			//
			// return '登录购买'
		},
		async onAndroidDownloadClicked() {
			if (this.isDownloading) {
				return
			}
			try {
				this.isDownloading = true
				showLoading()
				const downloadLink = await getAndroidDownloadLink()
				showToast(this.$t("开始下载"))
				startDownload(downloadLink)
				await reportVisit('android_download')
			} catch (e) {
				showToast(this.$t("下载失败"))
			} finally {
				hideLoading()
				this.isDownloading = false
			}
		},
		async onWindowsDownloadClicked() {
			// this.openWindowsDownloadModal = true
			if (this.isDownloading) {
				return
			}
			try {
				this.isDownloading = true
				showLoading()
				const downloadLink = await getWindowsDownloadLink()
				showToast(this.$t("开始下载"))
				startDownload(downloadLink)
				await reportVisit('windows_download')
			} catch (e) {
				showToast(this.$t("下载失败"))
			} finally {
				hideLoading()
				this.isDownloading = false
			}
		},
		async downloadWindowsApp(type) {
			if (this.isDownloading) {
				return
			}
			try {
				this.isDownloading = true
				showLoading()
				const downloadLink = await getWindowsDownloadLink(type)
				showToast(this.$t("开始下载"))
				startDownload(downloadLink)
				await reportVisit('windows_download')
			} catch (e) {
				showToast(this.$t("下载失败"))
			} finally {
				hideLoading()
				this.isDownloading = false
			}
		},
		async onIosDownloadClicked() {
			this.confirmModalTitle = '接下来您将跳转到苹果应用商店(App Store)。由于该应用仅在海外区域上架，您需要登陆海外地区苹果账号(Apple ID)才能成功下载安装。'
			this.showConfirmDialog = true
			this.confirmCallback = async () => {
				this.showConfirmDialog = false
				window.open('https://apps.apple.com/us/app/%E6%98%9F%E5%85%89%E5%8A%A0%E9%80%9F/id6742536670?l=zh-Hans-CN', '_blank');
				await reportVisit('ios_download')
			}
		},
		async onMacDownloadClicked() {

			if (this.isDownloading) {
				return
			}
			try {
				this.isDownloading = true
				showLoading()
				const downloadLink = await getR2MacosDownloadLink()
				showToast(this.$t("开始下载"))
				debugger
				startDownload(downloadLink)
				await reportVisit('macos_download')
			} catch (e) {
				showToast(this.$t("下载失败"))
			} finally {
				hideLoading()
				this.isDownloading = false
			}

		},
		onSlideChange(swiper) {
			// console.log(swiper.activeIndex);

			this.currentComment = this.userList[(swiper.realIndex + 1) % this.userList.length].comment
		},
		onDownloadClicked() {
			const ele = document.getElementById("nb_download_section");
			ele.scrollIntoView({behavior: 'smooth'})
			this.isMobileMenuOpen = false
		},
		onProductFeatureClicked() {
			const ele = document.getElementById("nb_product_feature_section");
			ele.scrollIntoView({behavior: 'smooth'})
			this.isMobileMenuOpen = false
		},
		onPurchaseMembershipClicked() {
			const ele = document.getElementById("nb_purchase_membership_section");
			ele.scrollIntoView({behavior: 'smooth'})
			this.isMobileMenuOpen = false
		},
		onUserCommentClicked() {
			const ele = document.getElementById("nb_user_comment_section");
			ele.scrollIntoView({behavior: 'smooth'})
			this.isMobileMenuOpen = false
		},
		onMobileMenuClicked() {
			this.isMobileMenuOpen = true
		},
		onMobileMenuMaskClicked() {
			this.isMobileMenuOpen = false
		},
		onRegisterModalClose() {
			this.isRegisterModalOpen = false
		},
		onRegisterAccountClicked() {
			this.isMobileMenuOpen = false
			if (this.$store.state.isLogin === true) {
				this.isAccountInfoModalOpen = true
			} else {
				this.isRegisterModalOpen = true
			}
		},
		onRegisterAccountSuccess() {
			this.isRegisterModalOpen = false
		},
		onLoginSuccess() {
			this.isRegisterModalOpen = false
		},
		onAccountInfoClose() {
			this.isAccountInfoModalOpen = false
		},
		onUserLogout() {
			this.confirmModalTitle = '确认登出？'
			this.showConfirmDialog = true
			this.confirmCallback = () => {
				this.showConfirmDialog = false
				this.isAccountInfoModalOpen = false
				this.$store.dispatch('logoutUser')
			}
		},
		onPaymentMethodModalClose() {
			this.isPaymentMethodModalOpen = false
		},
		onPaymentMethodModalOpen(planType) {
			showToast('请在应用内购买')
			// if (this.$store.state.isLogin !== true) {
			// 	showToast('请登录后购买')
			// 	return
			// }
			// this.isPaymentMethodModalOpen = true
			// this.selectedPlanType = planType
		},
		onConfirmBtnClick() {
			if (this.confirmCallback) {
				this.confirmCallback()
			}
		},
		onCancelBtnClick() {
			this.showConfirmDialog = false
		},
	}
}
</script>

<style scoped>
.nb-home-page-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
	position: relative;
}

.nb-mobile-menu-container {
	display: none;
}

.nb-first-section {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1em;
	box-sizing: border-box;
	position: relative;
	overflow: hidden;
}

.nb-banner-image-container {
	position: absolute;
	width: 100%;
	height: 100%;
	text-align: start;
	display: flex;
	align-items: center;
	justify-content: center;
}

.nb-banner-image {
	top: 0;
	left: 0;
	height: 100%;
	z-index: 1;
	opacity: 0.4;
}

.nb-header-container {
	width: 100%;
	display: flex;
	flex-direction: row;
	color: #ff8900;
	justify-content: center;
	align-items: center;
	z-index: 2;
	margin-bottom: 3em;
}

.nb-web-logo {
	width: 2.2em;
	height: 2.2em;
	margin-right: 0.5em;
}

.nb-web-title {
	flex: 1;
	font-size: 1.8em;
	line-height: 1.8em;
	text-align: start;
}

.nb-menu-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0 1em;
}

.nb-menu-item {
	font-size: 1.6em;
	margin: 0 1em;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.nb-sub-header {
	color: #ff8900;
	font-size: 1.6em;
	text-align: center;
	margin-bottom: 2em;
	z-index: 3;
	text-shadow: 1px 1px 5px #000000;
}

.nb-first-download-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 3;
}

.nb-products-image {
	width: 40em;
}

.nb-product-image-section {
	padding: 5em 2em;
}

.nb-download-text-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;
	align-self: stretch; /* 让子元素填满flex容器的交叉轴 */
}

/*.nb-download-text-section::after {*/
/*	content: '';*/
/*	position: absolute;*/
/*	top: 0;*/
/*	left: 0;*/
/*	right: 0;*/
/*	bottom: 0;*/
/*	background: rgba(0, 0, 0, 0.4); !* 在此设置覆盖层的颜色和透明度 *!*/
/*	z-index: 1; !* 确保覆盖层出现在图片之上 *!*/
/*}*/

.nb-first-section-download-btn {
	color: #ff8900;
	font-size: 1.6em;
	line-height: 1.6em;
	background-color: rgb(140, 81, 15);
	padding: 0.2em 1em;
	border-radius: 0.2em;
	margin-top: 0.4em;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	z-index: 3;
}

.nb-download-file-image {
	width: 1.2em;
	margin-left: 0.4em;
}

.nb-hr {
	background: radial-gradient(49.07% 49.07% at 50% 50.93%, #ff8900eb 0, rgba(49, 0, 0, 0) 100%);
	width: 100%;
	height: 1.5px;
	min-height: 1.5px;
	padding-top: 0.1em;
	padding-bottom: 0.1em;
	margin: 2em 0;
}

.nb-hr-footer {
	background: radial-gradient(49.07% 49.07% at 50% 50.93%, #ff8900eb 0, rgba(49, 0, 0, 0) 100%);
	width: 100%;
	height: 1.5px;
	min-height: 1.5px;
	padding-top: 0.1em;
	padding-bottom: 0.1em;
	margin-top: 2em;
}

.nb-feature-section {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.nb-feature-title {
	font-size: 2em;
	color: #E6D5B8;
}

.nb-download-section-title {
	font-size: 2em;
	color: #e45826;
}

.nb-feedback-section {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.nb-footer-section {
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #000000;
	padding: 3em 0;
	align-items: center;
	justify-content: center;
}

.nb-price-section-title {
	font-size: 2em;
	color: #ff8900;
}

.nb-price-section {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}


.nb-feature-grid-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 1em;
	padding: 3em 9em;
}

.nb-feature-img {
	width: 3em;
}

.nb-feature-card-text {
	font-size: 1.2em;
	color: #E6D5B8;
	margin-top: 1em;
}

.nb-feature-card-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: rgba(230, 213, 184, 0.3);
	padding: 1em 0;
	border-radius: 0.2em;
}

.nb-os-download-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 6em;
	padding: 3em;
}

.nb-os-download-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 1em 0;
}

.nb-os-download-image {
	height: 8em;
	max-width: 8em;
}

.nb-os-download-text {
	font-size: 1.6em;
	color: #e45826;
	padding: 1em 0;

}

.nb-os-download-btn {
	background-color: #e45826;
	font-size: 1.4em;
	line-height: 1.4em;
	color: #E6D5B8;
	padding: 0.2em 1em;
	border-radius: 0.2em;
	cursor: pointer;
}

.nb-price-card-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 6em;
	padding: 3em;
	width: 80em;
}

.nb-price-card {
	background-color: rgba(255, 137, 0, 0.3);
	border-radius: 0.4em;
	color: #ff8900;
	padding: 2em 0;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	position: relative;
}

.nb-price-card-first-charge-tag {
	position: absolute;
	width: 4em;
	top: 0;
	right: 0;
}

.nb-price-card-title {
	font-size: 1.4em;
	color: #ff8900;
	margin-bottom: 0.8em;
	font-weight: bold;
}

.nb-price-card-promo-title {
	font-size: 1.4em;
	color: #e45826;
	margin-bottom: 0.8em;
	font-weight: bold;
}

.nb-price-card-price, .nb-price-card-text {
	font-size: 1.4em;
	color: #ff8900;
	margin-bottom: 0.8em;
}

.nb-price-card-text-with-strip {
	font-size: 1.4em;
	color: #ff8900;
	margin-bottom: 0.8em;
	text-decoration-line: line-through; /* 定义装饰线类型为上划线 */
	text-decoration-color: #ff8900; /* 定义装饰线颜色为黑色 */
	text-decoration-style: solid; /* 定义装饰线样式为实线 */
}

.nb-price-card-promo-text {
	font-size: 1.8em;
	color: #ffe8e0;
	margin-bottom: 0.8em;
	text-shadow: 0 0 6px #e45826; /* 添加阴影 */
	animation: double-blink 3.6s ease-in infinite;

}

.nb-price-card-btn {
	background-color: #ff8900;
	font-size: 1.4em;
	line-height: 1.4em;
	color: #1f1f35;
	padding: 0.2em 1em;
	border-radius: 0.2em;
	cursor: pointer;
	width: fit-content;
	margin-top: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.nb-app-logo-image {
	width: 1.4em;
	margin-right: 0.8em;
}

.nb-app-title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.nb-footer-app-title {
	color: #ff8900;
	font-size: 1.2em;
	line-height: 1.2em;
	font-weight: bold;
}

.nb-footer-download-container {
	display: flex;
	flex-direction: row;
	padding: 1.6em 0;
	width: fit-content;
}

.nb-footer-download-img {
	width: 1em;
	max-height: 1em;
	margin-right: 0.4em;
}

.nb-footer-download-btn {
	display: flex;
	flex-direction: row;
	color: #ff8900;
	margin-right: 2em;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.nb-footer-download-text {

}

.nb-footer-address-container {
	color: #ff8900;
	font-size: 1em;
	margin-top: 1em;
}

.nb-footer-copy-right {
	color: #ff8900;
	font-size: 1em;
	margin-top: 1em;
}


.nb-swiper-content-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #E6D5B8;
}

.nb-swiper-container {
	width: 100%;
	margin-bottom: 3em;
	pointer-events: none;
}

.nb-swiper-container .nb-swiper-slide-container {
	pointer-events: all;
}

.nb-swiper-name {
	font-size: 1em;
	margin-top: 0.4em;
}

.nb-swiper-info {
	font-size: 1.1em;
	margin-top: 0.2em;
}

.nb-feedback-comment {
	padding: 3em 12em;
	color: #E6D5B8;
	font-size: 1.4em;
}

.nb-download-text-section::after {
	content: "";
	background-image: url('../images/banner.png');
	background-size: 100%; /* 宽度是100%，高度自动 */
	background-repeat: no-repeat;
	opacity: 0.5; /* 设置透明度为0.5 */
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
}

@media (max-width: 900px) {

	.nb-web-title {
		font-size: 1.6em !important;
		line-height: 1em !important;
	}

	.nb-products-image {
		width: 80% !important;
	}

	.nb-menu-item {
		display: none;
	}

	.nb-first-download-container {
		flex-direction: column !important;
	}

	.nb-download-text-section {
		order: 2;
	}

	.nb-banner-image-container {
		width: fit-content;
		height: fit-content;
	}

	.nb-banner-image {
		width: 100% !important;
		height: fit-content !important;
	}

	.nb-feature-grid-container {
		padding: 1em !important;
	}

	.nb-feature-card-text {
		min-height: 2em;
		font-size: 1.1em;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.nb-os-download-container {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 4em;
	}

	.nb-price-card-container {
		grid-template-columns: repeat(2, 1fr) !important;
		grid-gap: 1em !important;
		width: 80%;
	}

	.nb-feature-grid-container {
		grid-template-columns: repeat(2, 1fr);
	}

	.nb-feedback-comment {
		padding: 2em;
		color: #E6D5B8;
		font-size: 1.2em;
	}

	.nb-product-image-section {
		z-index: 10 !important;
	}

	.nb-swiper-img {
		width: 100% !important;
		height: fit-content !important;
	}

	.nb-footer-download-container {
		padding: 1em 0;
		width: 100% !important;
	}

	.nb-footer-download-btn {
		margin: 0 !important;
		flex: 1;
		font-size: 0.8em;
	}

	.nb-footer-address-container {
		margin-top: 0;
	}

	.nb-footer-section {
		padding: 2em 0;
	}

	.nb-menu-img {
		display: block !important;
	}

	.nb-mobile-menu-container {
		display: flex;
		position: fixed;
		flex-direction: column;
		right: 0;
		top: 0;
		height: 100%;
		color: #ff8900;
		z-index: 100;
		background-color: rgba(0, 0, 0, 0.8);
		font-size: 2em;
		padding: 1em 0.4em;
		align-items: start;
		justify-content: start;
		min-width: 7em;
	}

	.nb-mobile-menu-item {
		margin: 0.4em 0;
		border-bottom: 1px solid #ff8900;
		width: 100%;
		text-align: start;
		padding: 0.2em;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: start;
	}

	.nb-mobile-menu-mask {
		display: block !important;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		z-index: 99;
	}

	.nb-header-container {
		background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.7959558823529411) 50%, rgba(0, 212, 255, 0) 100%);
		position: fixed;
		top: 0;
		left: 0;
		padding: 0.6em;
		box-sizing: border-box;
		z-index: 20 !important;
		margin: 0 !important;
	}


	.select-download-type-container {
		padding: 0 !important;
	}

	.select-download-type-column {
		margin: 0 0.4em !important;
		padding: 0.4em !important;
		font-size: 0.9em !important;
	}

	.select-download-framework {
		font-size: 0.9em !important;
	}

	.select-download-type-download-btn {
		padding: 0.2em 0.6em !important;
		font-size: 0.9em !important;
	}
}

.nb-mobile-menu-mask {
	display: none;
}

.nb-menu-img {
	display: none;
}

.blur {
	filter: blur(10px);
}

.nb-mobile-menu-image {
	width: 1em;
	margin-right: 0.4em;
}

.nb-mobile-close-menu-image {
	width: 0.9em;
}

@keyframes blink {
	0%, 100% {
		opacity: 1; /* 不透明（完全可见） */
	}
	50% {
		opacity: 0; /* 完全透明（不可见） */
	}
}

@keyframes double-blink {
	0%, 10% {
		opacity: 1;
	}
	5%, 15% {
		opacity: 0;
	}
	20%, 100% {
		opacity: 1;
	}
}

.select-download-type-container {
	display: flex;
	flex-direction: row;
	padding: 1em;
	width: 100%;
	box-sizing: border-box;
}

.select-download-type-column {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0.8em;
	box-sizing: border-box;
	flex: 1;
	margin: 0 1em;
	border-radius: 0.2em;
	border: 1px solid #E6D5B8;
}

.select-download-framework {
	color: #E6D5B8;
	font-size: 1em;
}

.select-download-system {
	color: #E6D5B8;
	margin-top: 1em;
	font-size: 1em;
	flex: 1;
	width: 100%;
	text-align: start;
}

.select-download-type-download-btn {
	font-size: 1em;
	padding: 0.2em 0.8em;
	color: #2c3e50;
	background-color: #FF8900;
	border-radius: 0.2em;
	margin-top: 1em;
	width: fit-content;
	cursor: pointer;
}

.nb-download-os-image {
	width: 3em;
}

</style>