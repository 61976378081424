import {createRouter, createWebHistory} from 'vue-router';
import HomePage from './Pages/HomePage.vue';
import UsdtPaymentPage from './Pages/UsdtPaymentPage';
import ReferDownloadPage from './Pages/ReferDownloadPage';
import TechSupportPage from './Pages/TechSupportPage.vue';
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage.vue';
import PriceView from './Pages/SubPages/PriceView.vue';
import MainView from './Pages/SubPages/MainView.vue';
import DownloadView from './Pages/SubPages/DownloadView.vue';
import JoinUsView from './Pages/SubPages/JoinUsView.vue';

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: '/',
			component: HomePage,
			name: 'HomePage',
			redirect: '/main',
			children: [
				{
					path: 'priceview',
					name: 'priceview',
					component: PriceView
				},
				{
					path: 'main',
					name: 'mainview',
					component: MainView
				},
				{
					path: 'download',
					name: 'downloadView',
					component: DownloadView
				},
				{
					path: 'join',
					name: 'joinView',
					component: JoinUsView
				}
			]
		},
		{
			path: '/usdt',
			component: UsdtPaymentPage,
			name: 'UsdtPaymentPage'
		},
		{
			path: '/referDownload',
			component: ReferDownloadPage,
			name: 'referDownload'
		},
		{
			path: '/referRegister',
			component: ReferDownloadPage,
			name: 'referDownload'
		},
		{
			path: '/techSupport',
			component: TechSupportPage,
			name: 'techSupport'
		},
		{
			path: '/privacyPolicy',
			component: PrivacyPolicyPage,
			name: 'PrivacyPolicy'
		},
		{
			path: '/:pathMatch(.*)*',
			redirect: '/main'
		}
	]
});

export default router;