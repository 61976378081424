<template>
	<div class="tech-support-container">
		<div class="tech-input-field-container" v-if="isSubmit !== true">
			<div class="nb-register-account-label-margin">{{$t(`反馈.反馈原因`)}}</div>
			<div class='nb-login-email-field-container'>
				<input class="nb-login-input-email" type="text" :placeholder="$t(`反馈.请在此输入反馈原因`)" v-model="this.reason"/>
			</div>
			<div class="nb-register-account-label-margin">{{$t(`反馈.邮箱`)}}</div>
			<div class='nb-login-email-field-container'>
				<input class="nb-login-input-email" type="email" :placeholder="$t(`反馈.请在此输入邮箱`)" v-model="this.email"/>
			</div>
			<div class="nb-register-account-label-margin">{{$t(`反馈.反馈内容`)}}</div>
			<div class='nb-login-email-field-container'>
				<textarea class="nb-feedback-input-area" :placeholder="$t(`反馈.请在此输入反馈内容`)" v-model="this.content" rows="10"/>
			</div>

			<div class='nb-feedback-submit-btn-container'>
				<div class="nb-feedback-submit-btn no-select" @click="onFeedbackSubmit">{{$t(`反馈.提交`)}}</div>
			</div>
		</div>
		<div class="tech-input-field-container" v-if="isSubmit === true">
			<div class="tech-support-submit-success-container">
				<img src="../images/submit_success.svg" class="nb-feedback-success-img" alt="success">
				<div class="tech-support-submit-success-text">提交成功</div>
			</div>
		</div>
	</div>
</template>
<script>

import {hideLoading, postRequestAsync, showLoading, showToast, getFingerprint} from "@/utils/methods";

export default {
	name: "TechSupportPage",
	components: {},
	data() {
		return {
			reason: '',
			email: '',
			content: '',
			isSubmit: false,
			isSending: false
		}
	},
	async mounted() {

	},
	methods: {
		async onFeedbackSubmit() {
			if (this.isSending === true) {
				return
			}
			if (!this.reason) {
				showToast(this.$t("反馈.反馈原因不能为空"))
				return
			}
			if (!this.email) {
				showToast(this.$t("反馈.邮箱不能为空"))
				return
			}
			if (!this.content) {
				showToast(this.$t("反馈.反馈内容不能为空"))
				return
			}
			showLoading()
			const fp = await getFingerprint()
			const response = await postRequestAsync(`web/createTechSupportTicket`, {reason: this.reason, email: this.email, content: this.content, fp})
			hideLoading()
			if (response?.code === 200) {
				this.isSubmit = true
				this.isSending = false
			}
		}
	}
}
</script>
<style scoped>
.tech-support-container {
	padding: 0 20em;
}

@media (max-width: 900px) {
	.tech-support-container {
		padding: 1em !important;
	}

}
.tech-input-field-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: center;
	width: 100%;
	color: #ff8900;
	font-size: 1em;
}

.nb-register-account-label-margin {
	margin-top: 1.2em;
	font-size: 1.4em;
}

.nb-login-email-field-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	font-size: 1.4em;
}


.nb-login-input-email {
	border-top: none;
	border-right: none;
	border-left: none;
	border-bottom: 1px solid #FF8900;
	font-size: 1.4em;
	background: transparent;
	margin-top: 0.2em;
	flex: 1;
	width: inherit;
	color: #ff8900;
}

.nb-feedback-input-area{
	border: 1px solid #FF8900;
	font-size: 1.4em;
	background: transparent;
	margin-top: 0.2em;
	flex: 1;
	width: inherit;
	color: #ff8900;
	border-radius: 0.2em;
}
.nb-feedback-submit-btn {
	background-color: #FF8900;
	color: #3D3020;
	padding: 0.2em 0.6em;
	width: fit-content;
	margin-top: 0.6em;
	font-size: 1.6em;
	border-radius: 0.1em;
	cursor: pointer;
}

.nb-feedback-submit-btn:active {
	transform: scale(0.95);
}

.nb-feedback-submit-btn-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.tech-support-submit-success-text {
	color: #ff8900;
	font-size: 2em;
}

.tech-support-submit-success-container{
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2em;
	box-sizing: border-box;
}
.nb-feedback-success-img{
	width: 5em;
	padding: 1em;
}

</style>