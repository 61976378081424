<template>
	<div class="download-container" v-if="isMobile">

		<div class="download-os-picker-container-mobile">
			<div class="os-picker-label-container-mobile" :class="[currentView === 'IOS' ? 'os-selected' : '']" @click="onOsClicked('IOS')">
				<div class="os-picker-label-wrapper-mobile">
					<img class="os-picker-label-icon-mobile" src="../../images/downloadView/ios.svg" alt="ios">
					<div class="os-picker-label-text-mobile">iPhone/iPad</div>
				</div>
			</div>
			<div class="os-picker-label-container-mobile" :class="[currentView === 'ANDROID' ? 'os-selected' : '']" @click="onOsClicked('ANDROID')">
				<div class="os-picker-label-wrapper-mobile">
					<img class="os-picker-label-icon-mobile" src="../../images/downloadView/android.svg" alt="android">
					<div class="os-picker-label-text-mobile">Android</div>
				</div>

			</div>
			<div class="os-picker-label-container-mobile" :class="[currentView === 'MACOS' ? 'os-selected' : '']" @click="onOsClicked('MACOS')">
				<div class="os-picker-label-wrapper-mobile">
					<img class="os-picker-label-icon-mobile" src="../../images/downloadView/macos.svg" alt="macos">
					<div class="os-picker-label-text-mobile">macOS</div>
				</div>

			</div>
			<div class="os-picker-label-container-mobile" :class="[currentView === 'WINDOWS' ? 'os-selected' : '']" @click="onOsClicked('WINDOWS')">
				<div class="os-picker-label-wrapper-mobile">
					<img class="os-picker-label-icon-mobile" src="../../images/downloadView/windows.svg" alt="windows">
					<div class="os-picker-label-text-mobile">Windows</div>
				</div>

			</div>
		</div>
		<template v-if="this.currentView === 'IOS'">
			<IosDownloadSubView/>
		</template>
		<template v-if="this.currentView === 'ANDROID'">
			<AndroidDownloadSubView/>
		</template>
		<template v-if="this.currentView === 'MACOS'">
			<MacosDownloadSubView/>
		</template>
		<template v-if="this.currentView === 'WINDOWS'">
			<WindowsDownloadSubView/>
		</template>
	</div>
	<div class="download-container" v-else>
		<div class="download-os-picker-container">
			<div class="os-picker-label-container" @click="onOsClicked('IOS')">
				<div :class="[currentView === 'IOS' ? 'os-selected' : '']" class="os-picker-label-wrapper">
					<img class="os-picker-label-icon" src="../../images/downloadView/ios.svg" alt="ios">
					<div class="os-picker-label-text">iPhone/iPad</div>
				</div>
			</div>
			<div class="os-picker-label-container" @click="onOsClicked('ANDROID')">
				<div :class="[currentView === 'ANDROID' ? 'os-selected' : '']" class="os-picker-label-wrapper">
					<img class="os-picker-label-icon" src="../../images/downloadView/android.svg" alt="android">
					<div class="os-picker-label-text">Android</div>
				</div>

			</div>
			<div class="os-picker-label-container" @click="onOsClicked('MACOS')">
				<div :class="[currentView === 'MACOS' ? 'os-selected' : '']" class="os-picker-label-wrapper">
					<img class="os-picker-label-icon" src="../../images/downloadView/macos.svg" alt="macos">
					<div class="os-picker-label-text">macOS</div>
				</div>

			</div>
			<div class="os-picker-label-container" @click="onOsClicked('WINDOWS')">
				<div :class="[currentView === 'WINDOWS' ? 'os-selected' : '']" class="os-picker-label-wrapper">
					<img class="os-picker-label-icon" src="../../images/downloadView/windows.svg" alt="windows">
					<div class="os-picker-label-text">Windows</div>
				</div>

			</div>
		</div>
		<template v-if="this.currentView === 'IOS'">
			<IosDownloadSubView/>
		</template>
		<template v-if="this.currentView === 'ANDROID'">
			<AndroidDownloadSubView/>
		</template>
		<template v-if="this.currentView === 'MACOS'">
			<MacosDownloadSubView/>
		</template>
		<template v-if="this.currentView === 'WINDOWS'">
			<WindowsDownloadSubView/>
		</template>

	</div>
</template>
<script>

import IosDownloadSubView from "@/Pages/SubPages/DownloadSubViews/IosDownloadSubView.vue";
import AndroidDownloadSubView from "@/Pages/SubPages/DownloadSubViews/AndroidDownloadSubView.vue";
import MacosDownloadSubView from "@/Pages/SubPages/DownloadSubViews/MacosDownloadSubView.vue";
import WindowsDownloadSubView from "@/Pages/SubPages/DownloadSubViews/WindowsDownloadSubView.vue";
import platform from 'platform';

export default {
	name: "DownloadView",
	components: {
		IosDownloadSubView,
		AndroidDownloadSubView,
		MacosDownloadSubView,
		WindowsDownloadSubView
	},
	inject: ['isMobile'],
	data() {
		return {
			currentView: 'IOS' // IOS ANDROID MACOS WINDOWS
		}
	},
	mounted() {
		const os = platform.os.family
		console.log(`os: ${os}`)
		if (os.indexOf('Windows') > -1) {
			this.currentView = 'WINDOWS'
		} else if (os.indexOf('iOS') > -1) {
			this.currentView = 'IOS'
		} else if (os.indexOf('Android') > -1) {
			this.currentView = 'ANDROID'
		} else if (os.indexOf('Mac') > -1) {
			this.currentView = 'IOS'
		}
	},
	methods: {
		onOsClicked(os) {
			this.currentView = os
		}
	}
}
</script>
<style scoped>
.download-container {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.download-os-picker-container {
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 两列，宽度相等 */
	grid-template-rows: 1fr; /* 两行，高度相等 */
	grid-gap: 1em;
	background-color: #ffffff;
	justify-content: center;
	align-items: center;
	padding: 0 9em;
	box-sizing: border-box;
}

.download-os-picker-container-mobile{
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 两列，宽度相等 */
	grid-template-rows: 1fr; /* 两行，高度相等 */
	grid-gap: 1em;
	background-color: #ffffff;
	justify-content: center;
	align-items: center;
	padding: 0;
	box-sizing: border-box;
}

.os-picker-label-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.os-picker-label-container-mobile{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex: 1;
}

.os-picker-label-icon {
	width: 2em;
	margin-right: 0.8em;
}

.os-picker-label-icon-mobile{
	width: 2em;
}

.os-picker-label-text {
	font-size: 2em;
}

.os-picker-label-text-mobile{
	font-size: 1.2em;
}

.os-selected {
	border-bottom: 2px solid #EF9D4A;
}

.os-picker-label-wrapper {
	display: flex;
	flex-direction: row;
	padding: 1em 0;
}

.os-picker-label-wrapper-mobile{
	display: flex;
	flex-direction: column;
	padding: 1em 0;
	align-items: center;
	gap: 1em;
}

</style>