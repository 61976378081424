export default {
	"网页标题": "Astral VPN",
	"星云加速器": "Astral VPN",
	"首页": "Home",
	"售价": "Price",
	"下载": "Download",
	"加入推广伙伴": "Join Us",
	"别具一格的网络服务": "Distinctive network service",
	"为您带来畅快体验": "Bringing you a smooth and enjoyable experience",
	"节点覆盖全球": "Global Node Coverage",
	"高速节点遍布全球20+国家": "High-speed nodes distributed across 20+ countries worldwide",
	"极速、稳定、隐私": "Ultra-fast, Stable, Private",
	"无追踪保障": "Tracking-free Guarantee",
	"不保存任何敏感信息": "No sensitive information stored",
	"全面守护隐私": "Comprehensive Privacy Protection",
	"支持AI与流媒体": "Supports AI and Streaming Services",
	"跨越国境": "Cross-border Access",
	"访问专属内容": "Access Exclusive Content",
	"非实名账号": "Anonymous Accounts",
	"支持非实名账号登录": "Supports Login Without Real Identity",
	"保护用户身份信息": "Protects User Identity Information",
	"全客户端支持": "Full Client Support",
	"支持主流操作系统": "Supports Major Operating Systems",
	"一键开启高速冲浪": "One-click High-speed Browsing",
	"快速的售后支持": "Rapid Customer Support",
	"实时在线客服": "Real-time Online Customer Service",
	"提供持续友好的服务": "Providing Continuous Friendly Service",
	"无流量限制": "Unlimited Traffic",
	"不限速，不限流": "No Speed Limits, No Data Caps",
	"带来无比丝滑的浏览体验": "Delivers an Incredibly Smooth Browsing Experience",
	"数据加密": "Data Encryption",
	"企业级加密技术": "Enterprise-level Encryption Technology",
	"保障用户信息安全": "Ensures User Information Security",
	"住宅IP供应": "Residential IP Supply",
	"内含多国住宅IP": "Includes Residential IPs from Multiple Countries",
	"轻松解锁专业用途": "Easily Unlocks Professional Applications",
	"版权所有 © 2025 星云团队": "Copyright © 2025 Astral Team",
	"有问题找客服": "If you have any questions, contact customer service",
	"星云客户支持服务在线中...": "Astral customer support service online...",
	"星云加速客户支持服务中心": "Astral VPN Customer Support Service Center",
	"工作时间回复平均时长：24分钟": "Average response time during working hours: 24 minutes",
	"有什么我可以帮到你？": "How may I help you?",
	"请在这里输入信息": "Please enter your message here",
	"我": "Me",
	"星云加速客服": "Astral VPN Customer Service",
	"发送": "Send",
	"VIP标准年卡": "VIP Standard Annual Plan",
	"VIP标准半年卡": "VIP Standard 6-Month Plan",
	"VIP标准季卡": "VIP Standard Quarterly Plan",
	"VIP标准月卡": "VIP Standard Monthly Plan",
	"市场价：": "Market Price: ",
	"节省 {save}%": "Save {save}%",
	"可同时使用设备数：": "Maximum Concurrent Devices: ",
	"{days}天会员资格": "{days} Days Membership",
	"1年会员资格": "1 Year Membership",
	"180天会员资格": "180 Days Membership",
	"90天会员资格": "90 Days Membership",
	"30天会员资格": "30 Days Membership",
	"不限流量 不限速": "Unlimited Data & No Speed Limits",
	"快速技术支持": "Rapid Technical Support",
	"晚高峰无卡顿": "No Lag During Peak Hours",
	"全球超过20个国家节点": "Nodes in Over 20 Countries Worldwide",
	"包含住宅IP": "Includes Residential IPs",
	"支持区域流媒体": "Supports Regional Streaming Services",
	"约": "About",
	"Windows客户端": "Windows Client",
	"下载Windows专用客户端": "Download Windows Dedicated Client",
	"享受无限制的全球网络服务": "Enjoy Unlimited Global Network Services",
	"macOS客户端": "macOS Client",
	"下载安全可靠的Mac客户端": "Download the Safe and Reliable Mac Client",
	"立刻开始极速的全球连接": "Begin High-Speed Global Connection Now",
	"Android客户端": "Android Client",
	"下载安全可靠的Android客户端": "Download the Safe and Reliable Android Client",
	"iPhone/iPad客户端": "iPhone/iPad Client",
	"从App Store下载客户端": "Download Client from App Store",
	"需要您使用其他国家或地区Apple ID账号": "Download the Safe and Reliable Android Client",
	"本应用已经过苹果App Store安全审核": "This Application Has Passed Apple App Store Security Review",
	"无后门无敏感数据采集": "No Backdoors or Sensitive Data Collection",
	"现在加入星云加速推广计划": "Join the Astral VPN Affiliate Program Now",
	"立即开始构建稳定收入，让赚钱加倍轻松": "Start Building Stable Income Immediately, Making Earning Money Twice as Easy",
}