<template>

	<template v-if="isMobile === true">
		<div class="support-container" v-if="isWebsocketConnected === true">
			<template v-if="currentState=== 'CHAT_WINDOW' && showSupportBox === true">
				<div class="support-box-window" ref="supportBox">
					<img class="support-box-step-1-close" src="../images/supportComponent/close.svg" alt="close"
					     @click="closeSupportBox()">
					<div class="support-box-header-container">
						<img class="support-team-bg" src="../images/supportComponent/support_team_bg.png"
						     alt="support team bg">
						<img class="support-team-avatar" src="../images/supportComponent/support_team_avatar.png"
						     alt="support team">
						<div class="support-box-header-text">{{localize('星云加速客户支持服务中心')}}</div>
						<div class="support-box-header-text-2-container">
							<div class="green-dot"/>
							<div class="support-box-header-text-2">{{localize('工作时间回复平均时长：24分钟')}}</div>
						</div>

					</div>
					<div class="support-msg-container-window">

						<ChatComponent :chat-item="{type: 'cs', msg: this.localize('有什么我可以帮到你？')}"/>
						<template v-for="(item) in this.msgList" :key="item.createTimestamp">
							<ChatComponent :chat-item="item"/>
						</template>

					</div>
					<div class="support-msg-input-container">
						<textarea class="support-msg-input" rows="4" :placeholder="this.localize('请在这里输入信息')"
						          v-model="inputText"/>
						<div class="support-msg-tool-bar-container">
							<div class="tool-bar-left">
								<input
									type="file"
									ref="fileInput"
									@change="onFileChange"
									accept="image/*"
									style="display: none"
								>
								<img class="tool-bar-icon" src="../images/supportComponent/attachment.svg"
								     alt="attachment"
								     @click="onAttachFile">
								<img ref="emojiButton" class="tool-bar-icon" src="../images/supportComponent/emoji.svg"
								     alt="emoji" @click="toggleEmojiPicker">
							</div>
							<div class="tool-bar-send-btn no-select" @click="onSendMsg">{{localize('发送')}}</div>
							<!-- 使用Teleport将floating-div传送到base-container上 -->
						</div>
					</div>
				</div>
			</template>

			<img class="support-icon" src="../images/supportComponent/support_icon.svg" alt="support icon"
			     @click="viewSupportBox()" v-if="showSupportBox !== true">
		</div>
	</template>

	<template v-else>
		<div class="support-container" v-if="isWebsocketConnected === true">
			<template v-if="currentState=== 'STEP1' && showSupportBox === true">
				<div class="support-box-step-1">
					<img class="support-box-step-1-close" src="../images/supportComponent/close.svg" alt="close"
					     @click="closeSupportBox()">
					<div class="find-support-text">{{localize('有问题找客服')}}</div>
					<div class="support-online-text-container">
						<div class="green-dot"/>
						<div class="support-online-text">{{localize('星云客户支持服务在线中...')}}</div>
					</div>
					<img class="contact-now-btn" src="../images/supportComponent/contact_now_btn.svg" alt="contact now"
					     @click="changeState('STEP2')" v-if="langStore.getCurrentLanguage === 'CN'">
					<img class="contact-now-btn" src="../images/supportComponent/contact_now_btn_en.svg" alt="contact now"
					     @click="changeState('STEP2')" v-else>
				</div>
			</template>
			<template v-if="currentState=== 'STEP2' && showSupportBox === true">
				<div class="support-box-step-2">
					<img class="support-box-step-1-close" src="../images/supportComponent/close.svg" alt="close"
					     @click="closeSupportBox()">
					<div class="support-box-header-container">
						<img class="support-team-bg" src="../images/supportComponent/support_team_bg.png"
						     alt="support team bg">
						<img class="support-team-avatar" src="../images/supportComponent/support_team_avatar.png"
						     alt="support team">
						<div class="support-box-header-text">{{localize('星云加速客户支持服务中心')}}</div>
						<div class="support-box-header-text-2-container">
							<div class="green-dot"/>
							<div class="support-box-header-text-2">{{localize('工作时间回复平均时长：24分钟')}}</div>
						</div>

					</div>
					<div class="support-msg-container">

						<ChatComponent :chat-item="{type: 'cs', msg: this.localize('有什么我可以帮到你？')}"/>
						<template v-for="(item) in this.msgList" :key="item.createTimestamp">
							<ChatComponent :chat-item="item"/>
						</template>

					</div>
					<div class="support-msg-input-container">
						<textarea class="support-msg-input" rows="4" :placeholder="this.localize('请在这里输入信息')"
						          v-model="inputText"/>
						<div class="support-msg-tool-bar-container">
							<div class="tool-bar-left">
								<input
									type="file"
									ref="fileInput"
									@change="onFileChange"
									accept="image/*"
									style="display: none"
								>
								<img class="tool-bar-icon" src="../images/supportComponent/attachment.svg"
								     alt="attachment"
								     @click="onAttachFile">
								<img ref="emojiButton" class="tool-bar-icon" src="../images/supportComponent/emoji.svg"
								     alt="emoji" @click="toggleEmojiPicker">
							</div>
							<div class="tool-bar-send-btn no-select" @click="onSendMsg">{{localize('发送')}}</div>
							<!-- 使用Teleport将floating-div传送到base-container上 -->
						</div>
					</div>
				</div>
			</template>

			<img class="support-icon" src="../images/supportComponent/support_icon.svg" alt="support icon"
			     @click="viewSupportBox()">
		</div>
	</template>
	<Teleport to="body">
		<div
			v-if="showEmojiPicker"
			class="emoji-picker-container"
			:style="emojiPickerPosition">
			<EmojiPickerComponent @select="insertEmoji"/>
		</div>
	</Teleport>
</template>

<script>


import EmojiPickerComponent from "@/components/EmojiPickerComponent.vue"
import ChatComponent from "@/components/ChatComponent.vue"
import socketService from '../utils/socketService'
import {getServerUrl, getFingerprint} from '@/utils/methods'
import platform from 'platform'

const momentTz = require('moment-timezone')

export default {
	name: "SupportComponent",
	components: {
		EmojiPickerComponent,
		ChatComponent
	},
	data() {
		return {
			currentState: 'STEP1',
			showSupportBox: true,
			showEmojiPicker: false,
			emojiPickerPosition: {
				bottom: '0px',
				right: '0px'
			},
			inputText: '',
			isWebsocketConnected: false,
			msgList: [],
			socket: null
		}
	},
	inject: ['socket', 'isMobile', 'langStore', 'localize'],
	async mounted() {

		if (this.isMobile === true) {
			this.showSupportBox = false

			// // 立即设置一次高度
			// this.setHeight()
			//
			// // 然后在多个时间点再次尝试设置高度
			// setTimeout(() => this.setHeight(), 100)
			// setTimeout(() => this.setHeight(), 300)
			// setTimeout(() => this.setHeight(), 1000)
			//
			// // 使用requestAnimationFrame确保在下一帧渲染前设置正确高度
			// requestAnimationFrame(() => {
			// 	this.setHeight()
			// })
			//
			// // 添加事件监听
			// window.addEventListener('resize', this.setHeight)
			// window.addEventListener('scroll', this.setHeight)
			// window.addEventListener('orientationchange', this.setHeight)
			//
			// // 添加触摸事件监听，在用户首次触摸屏幕时重新计算
			// document.addEventListener('touchstart', this.setHeight, { once: true })
			//
			// // 确保在页面完全加载后再次设置高度
			// window.addEventListener('load', this.setHeight)
		}
		// 添加点击外部关闭事件
		document.addEventListener('click', this.clickOutside);

		// 添加窗口调整大小事件，更新位置
		window.addEventListener('resize', () => {
			if (this.showEmojiPicker) {
				this.updateEmojiPickerPosition()
			}
		});
		const url = await getServerUrl()
		this.socket = await socketService.setupSocketConnection(url)
		this.socket?.on('message', () => {
			console.log('接收到信息')
		})
		this.socket?.on('updateMsgList', (data) => {
			this.msgList = JSON.parse(data)
			this.scrollToBottom()
		})
		this.socket?.on('connect', async () => {
			console.log('websocket连接已建立')
			this.isWebsocketConnected = true
			const fp = await getFingerprint()
			socketService.emit('getMsg', fp)
		});
		this.socket?.on('connect_error', () => {
			console.log('websocket连接出现错误')
		});
	},
	beforeUnmount() {
		// 移除事件监听器
		document.removeEventListener('click', this.clickOutside);
		window.removeEventListener('resize', this.updateEmojiPickerPosition);
		socketService.getSocket()?.off('message', () => {

		});
	},
	methods: {
		changeState(state) {
			this.currentState = state
			if (this.currentState === 'STEP2') {
				this.scrollToBottom()
			}
		},
		closeSupportBox() {
			this.showSupportBox = false
		},
		onEmojiBtnClick() {
			this.showEmojiPicker = true
		},
		viewSupportBox() {
			this.showSupportBox = true
			if (this.isMobile === true) {
				this.currentState = 'CHAT_WINDOW'
				this.setHeight()
				setTimeout(()=>{
					this.setHeight()
				}, 1000)
				setTimeout(()=>{
					this.setHeight()
				}, 2000)
			} else {
				if (this.currentState === 'STEP2') {
					this.scrollToBottom()
				}
			}

		},
		toggleEmojiPicker() {
			this.showEmojiPicker = !this.showEmojiPicker;

			if (this.showEmojiPicker) {
				// 如果显示表情选择器，计算位置
				this.$nextTick(() => {
					this.updateEmojiPickerPosition();
				});
			}
		},
		updateEmojiPickerPosition() {
			const buttonRect = this.$refs.emojiButton.getBoundingClientRect();

			// 设置初始底部位置，将选择器放在按钮上方，并留出10px间距
			const initialBottom = window.innerHeight - buttonRect.top + 10;

			// 初始设置一个临时位置，使元素能够渲染出来
			this.emojiPickerPosition = {
				left: `${buttonRect.left}px`,
				bottom: `${initialBottom}px`
			};

			// 等待DOM更新，然后获取实际尺寸并调整位置
			this.$nextTick(() => {
				const emojiPickerElement = document.querySelector('.emoji-picker-container');

				if (emojiPickerElement) {
					const emojiPickerWidth = emojiPickerElement.offsetWidth;
					const emojiPickerHeight = emojiPickerElement.offsetHeight;

					// 计算按钮的中心点
					const buttonCenterX = buttonRect.left + (buttonRect.width / 2);

					// 计算表情选择器居中位置
					let emojiPickerLeft = buttonCenterX - (emojiPickerWidth / 2);

					// 检查和调整水平边界
					if (emojiPickerLeft < 10) {
						// 不要让选择器太靠近左边界，至少保留10px间距
						emojiPickerLeft = 10;
					} else if (emojiPickerLeft + emojiPickerWidth > window.innerWidth - 10) {
						// 不要让选择器太靠近右边界，至少保留10px间距
						emojiPickerLeft = window.innerWidth - emojiPickerWidth - 10;
					}

					// 检查垂直方向是否有足够空间
					let positionStyle = {};

					if (buttonRect.top > emojiPickerHeight + 20) {
						// 上方有足够空间，将选择器放在按钮上方
						positionStyle = {
							left: `${emojiPickerLeft}px`,
							bottom: `${initialBottom}px`
						};
					} else {
						// 上方空间不足，将选择器放在按钮下方
						positionStyle = {
							left: `${emojiPickerLeft}px`,
							top: `${buttonRect.bottom + 10}px`
						};
					}

					// 更新位置
					this.emojiPickerPosition = positionStyle;
				}
			});
		},
		insertEmoji(emoji) {
			// 在光标位置插入表情
			this.inputText += emoji;

			// 插入后聚焦回输入框
			this.$nextTick(() => {
				const textarea = document.querySelector('.support-msg-input');
				if (textarea) {
					textarea.focus();
				}
			});
			this.showEmojiPicker = false
		},
		clickOutside(event) {
			// 点击外部关闭表情选择器
			if (this.showEmojiPicker) {
				const emojiPicker = document.querySelector('.emoji-picker-container');
				const emojiButton = this.$refs.emojiButton;

				if (emojiPicker && !emojiPicker.contains(event.target) &&
					emojiButton && !emojiButton.contains(event.target)) {
					this.showEmojiPicker = false;
				}
			}
		},
		async onSendMsg() {
			if (!this.inputText) {
				return
			}

			const fp = await getFingerprint()
			socketService.emit('sendMsg', JSON.stringify({
				fp: fp, msg: this.inputText, platform: {
					name: platform.name,           // 浏览器名称
					version: platform.version,     // 浏览器版本
					layout: platform.layout,       // 浏览器渲染引擎
					os: platform.os,               // 操作系统信息
					description: platform.description, // 完整描述
					product: platform.product,     // 设备名称
					manufacturer: platform.manufacturer, // 设备制造商
					prerelease: platform.prerelease, // 预发布标识符
				}
			}))
			this.inputText = ''
		},
		scrollToBottom() {
			this.$nextTick(() => {
				const container = document.querySelector('.support-msg-container');
				if (!container) return;

				const targetPosition = container.scrollHeight - container.clientHeight;
				const currentPosition = container.scrollTop;
				const scrollDistance = targetPosition - currentPosition;

				// 如果滚动距离很小（小于200px），直接跳转
				if (scrollDistance < 200) {
					container.scrollTop = targetPosition;
					return;
				}

				// 动画参数
				const duration = 300; // 毫秒
				const startTime = performance.now();
				const startPosition = currentPosition;

				function animateScroll(currentTime) {
					const elapsedTime = currentTime - startTime;

					if (elapsedTime >= duration) {
						container.scrollTop = targetPosition;
						return;
					}

					// 使用easeOutQuad缓动函数
					const progress = elapsedTime / duration;
					const easeProgress = 1 - (1 - progress) * (1 - progress);

					container.scrollTop = startPosition + scrollDistance * easeProgress;
					requestAnimationFrame(animateScroll);
				}

				requestAnimationFrame(animateScroll);
			});
		},
		onAttachFile() {
			this.$refs.fileInput.click();
		},
		onFileChange(e) {
			const file = e.target.files[0];
			if (!file) return;

			this.msgList.push({
				createTimestamp: parseInt(momentTz().format('X')),
				createTimeString: momentTz().tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss'),
				image: URL.createObjectURL(file),
				imageFile: file,
				type: 'customer'
			})
			this.$nextTick(() => {
				this.scrollToBottom()
			})
		},
		setHeight() {
			this.windowHeight = window.innerHeight
			if (this.$refs.supportBox) {
				this.$refs.supportBox.style.height = `${this.windowHeight}px`
			}
		}
	}
}
</script>

<style scoped>
.emoji-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
	gap: 10px;
}

.emoji-picker-container {
	position: fixed;
}

textarea:focus {
	outline: none;
}

textarea {
	border-radius: 0;
	resize: none;
}

textarea::placeholder {
	color: #b0b0b0; /* 修改为你想要的颜色 */
}

.support-container {
	position: fixed;
	z-index: 999;
	right: 4em;
	bottom: 4em;
	width: 6em;
	display: flex;
	flex-direction: column;
	align-items: end;
}

.support-icon {

	cursor: pointer;
	filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.3));
}


.support-icon:active {
	transform: scale(0.95);
}

.find-support-text {
	color: #353332;
	font-size: 1.4em;
	margin-bottom: 0.4em;
	font-weight: bold;
	text-align: start;
}

.support-online-text {
	color: #1D00EB;
	font-size: 1.2em;
	text-align: start;
}

.green-dot {
	width: 10px;
	height: 10px;
	background-color: #52d600;
	border-radius: 50%;
	display: inline-block;
	margin-right: 0.4em;
}

.support-box-step-1 {
	width: 20em;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: start;
	padding: 1em;
	border-radius: 0.4em;
	margin-bottom: 1em;
	filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.3));
	position: relative;
	overflow: hidden;
}

.support-box-step-2 {
	width: 30em;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: start;
	border-radius: 0.4em;
	margin-bottom: 1em;
	filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.3));
	position: relative;
	overflow: hidden;
}

.support-box-window {
	width: 100%;
	//height: 100vh;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: start;
	border-radius: 0.4em;
	margin-bottom: 1em;
	filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.3));
	position: fixed;
	top: 0;
	left: 0;
	overflow: hidden;
}

.support-online-text-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1em;
}

.contact-now-btn {
	width: 8em;
	cursor: pointer;
}

.contact-now-btn:active {
	transform: scale(0.95);
}

.support-box-step-1-close:active {
	transform: scale(0.95);
}

.support-box-step-1-close {
	width: 1.2em;
	position: absolute;
	cursor: pointer;
	right: 1em;
	top: 1em;
	z-index: 999;
}

.support-box-header-container {
	background-color: #457CBF;
	top: 0;
	left: 0;
	padding: 1em;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	overflow: hidden;
}

.support-team-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	opacity: 0.4;
	z-index: 0;
}

.support-box-header-text-2-container {
	display: flex;

	box-sizing: border-box;
	flex-direction: row;
	align-items: center;
	margin-top: 0.6em;

	z-index: 1;
}

.support-box-header-text {
	color: #ffffff;
	width: fit-content;
	font-size: 1.2em;
	margin-top: 0.6em;
	z-index: 1;
}

.support-box-header-text-2 {
	color: #e1e1e1;
	width: fit-content;
	font-size: 1em;
}

.support-msg-container {
	background-color: #ffffff;
	height: 20em;
	width: 100%;
	overflow-y: auto;
}

.support-msg-container-window {
	background-color: #ffffff;
	flex: 1;
	width: 100%;
	overflow-y: auto;
}

.support-msg-input-container {
	background-color: #ffffff;
	width: 100%;
	padding: 1em;
	box-sizing: border-box;
}

.support-msg-tool-bar-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.support-team-avatar {
	width: 10em;
	z-index: 1;
}

.support-msg-input {
	border: none;
	width: 100%;
	box-sizing: border-box;
	border-top: 1px solid #e1e1e1;
	font-size: 1.2em;
}

.tool-bar-icon {
	width: 2em;
	cursor: pointer;
}

.tool-bar-send-btn {
	padding: 0.4em 1em;
	font-size: 1.2em;
	border: 1px solid #858585;
	cursor: pointer;
	border-radius: 0.2em;
	line-height: 1.2em;
}

.tool-bar-send-btn:active {
	transform: scale(0.95);
}

.tool-bar-left {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.4em;
}
</style>