<template>
	<div class="price-view-container">
		<div class="price-header-container">
			<img class="price-header" src="../../images/priceView/price_header.svg" alt="price header" v-if="langStore.getCurrentLanguage === 'CN'">
			<img class="price-header-en" src="../../images/priceView/price_header_en.svg" alt="price header" v-else>
		</div>
		<div class="price-card-container-mobile" v-if="isMobile">

			<template v-for="(item) in this.priceList" :key="item.name">
				<PriceCardView :plan-item="item" :name="item.name" :price="item.price" :market-price="item.marketPrice" :save="item.save" :device-count="item.deviceCount"/>
			</template>
		</div>
		<div class="price-card-container" v-else>
			<template v-for="(item) in this.priceList" :key="item.name">
				<PriceCardView :plan-item="item" :name="item.name" :price="item.price" :market-price="item.marketPrice" :save="item.save" :device-count="item.deviceCount"/>
			</template>
		</div>
	</div>
</template>
<script>

import PriceCardView from "@/components/PriceCardView.vue";
export default {
	name: "PriceView",
	components: {
		PriceCardView
	},
	inject: ['isMobile', 'langStore', 'localize'],
	data() {
		return {
			priceList: [
				{
					name: 'VIP标准年卡',
					price: 165,
					marketPrice: 400,
					save: 59,
					deviceCount: 4,
					duration: 365
				},
				{
					name: 'VIP标准半年卡',
					price: 95,
					marketPrice: 240,
					save: 40,
					deviceCount: 4,
					duration: 180
				},
				{
					name: 'VIP标准季卡',
					price: 55,
					marketPrice: 130,
					save: 30,
					deviceCount: 4,
					duration: 90
				},
				{
					name: 'VIP标准月卡',
					price: 20,
					marketPrice: 30,
					save: 25,
					deviceCount: 4,
					duration: 30
				}
			]
		}
	}
}
</script>
<style scoped>
.price-view-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100vh;
}

.price-view-container-mobile{
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh;
}

.price-header-container {
	background: linear-gradient(119deg, rgba(255, 253, 242, 1) 0%, rgba(255, 247, 245, 1) 100%);
	padding: 3em 0;
}

.price-header {
	width: 20em;
}
.price-header-en {
	width: 30em;
}

.price-card-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr; /* 两列，宽度相等 */
	grid-template-rows: 1fr; /* 两行，高度相等 */
	grid-gap: 2em;
	width: 100%;
	flex-direction: row;
	align-items: center;
	background-color: #ffffff;
	padding: 0 9em;
	justify-content: center;
	box-sizing: border-box;
	flex: 1;
}

.price-card-container-mobile{
	display: grid;
	grid-template-columns: 1fr; /* 两列，宽度相等 */
	grid-template-rows: 4fr; /* 两行，高度相等 */
	grid-gap: 2em;
	width: 100%;
	flex-direction: row;
	align-items: center;
	background-color: #ffffff;
	padding: 3em 2em;
	justify-content: center;
	box-sizing: border-box;
	flex: 1;
}

</style>