<template>
	<div class="price-card-wrapper">
		<div class="price-card-header-container">
			<div :class="{'price-card-name': langStore.getCurrentLanguage === 'CN', 'price-card-name-en': langStore.getCurrentLanguage !== 'CN'}">{{ localize(this.name) }}</div>
			<div class="price-card-price">￥{{ this.price }}</div>
			<div class="price-card-promo-container">
				<div class="market-price">{{localize('市场价：')}}{{localize('约')}}￥{{ this.marketPrice }}</div>
				<div class="save-percentage">{{localize('节省 {save}%', {save: this.planItem.save})}}</div>
			</div>
		</div>
		<div class="price-card-benefit-container">
			<div class="device-count">{{localize('可同时使用设备数：')}}{{ this.deviceCount }}</div>
			<div class="benefit-item-container">
				<div class="orange-dot"/>
				<div class="benefit-item">{{localize('{days}天会员资格', {days: this.planItem.duration})}}</div>
			</div>
			<div class="benefit-item-container">
				<div class="orange-dot"/>
				<div class="benefit-item">{{localize('不限流量 不限速')}}</div>
			</div>
			<div class="benefit-item-container">
				<div class="orange-dot"/>
				<div class="benefit-item">{{localize('快速技术支持')}}</div>
			</div>
			<div class="benefit-item-container">
				<div class="orange-dot"/>
				<div class="benefit-item">{{localize('晚高峰无卡顿')}}</div>
			</div>
			<div class="benefit-item-container">
				<div class="orange-dot"/>
				<div class="benefit-item">{{localize('全球超过20个国家节点')}}</div>
			</div>
			<div class="benefit-item-container">
				<div class="orange-dot"/>
				<div class="benefit-item">{{localize('包含住宅IP')}}</div>
			</div>
			<div class="benefit-item-container">
				<div class="orange-dot"/>
				<div class="benefit-item">{{localize('支持区域流媒体')}}</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: "PriceCardView",
	components: {},
	data() {
		return {}
	},
	inject: ['isMobile', 'langStore', 'localize'],
	props: ['name', 'price', 'marketPrice', 'save', 'deviceCount', 'planItem'],
}
</script>
<style scoped>
.price-card-wrapper {
	display: flex;
	flex-direction: column;
	padding: 1em 2em;
	border-radius: 0.8em;
	background-color: #ffffff;
	filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
	min-width: 22em;
}

.price-card-name {
	font-size: 2em;
	color: #1F1F1F;
}
.price-card-name-en {
	font-size: 1.4em;
	color: #1F1F1F;
	font-weight: bold;
}

.price-card-price {
	font-size: 2.2em;
	color: #1F1F1F;
}

.price-card-promo-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	font-size: 1.2em;
	justify-content: space-between;
}

.price-card-header-container {
	border-bottom: 1px solid #E6E4E3;
	padding: 1em 0;
}

.price-card-benefit-container {
	display: flex;
	flex-direction: column;
	align-items: start;
	padding: 1em 0;
}

.device-count {
	color: #D76100;
	padding: 0.4em 0.6em;
	background-color: #FFF2EA;
	margin-top: 0.4em;
	font-size: 1.2em;
}

.save-percentage {
	color: #D76100;
}

.orange-dot {
	width: 8px;
	height: 8px;
	background-color: #D76100;
	border-radius: 50%;
	display: inline-block;
	margin-right: 0.8em;
}

.benefit-item-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 0.4em;
}

.benefit-item {
	font-size: 1.4em;
	color: #1F1F1F;
	text-align: start;
}

.market-price{
	text-decoration: line-through;
}
</style>