<template>
	<div class="pp-container">
		<template v-if="this.getDefaultLang() !== 'en'">
			<div class="pp-title">隐私政策</div>
			<div class="pp-content">
				<p>尊敬的用户，感谢您选择使用我们的加速器服务。本隐私政策旨在明确说明我们如何收集、使用、保护和处理您的个人信息。请仔细阅读以下内容，以了解我们的隐私保护措施。</p>
				<p>
					<b>信息收集</b> <br>
					我们可能收集以下信息：<br>
					• 账户信息：包括您的电子邮件地址和付款信息；<br>
					• 使用数据：连接时间、使用时长、选择的服务器；<br>
					• 设备信息：设备类型、操作系统版本、唯一设备标识符；<br>
					• 网络信息：IP地址、网络连接状态。
				</p>

				<p>
					<b>信息使用</b><br>
					我们使用收集的信息用于：<br>
					• 提供、维护和改进我们的VPN服务；<br>
					• 处理您的订阅和付款；<br>
					• 技术支持和客户服务；<br>
					• 网络安全和防止欺诈。
				</p>

				<p>
					<b>日志政策</b><br>
					我们实行严格的无日志政策：<br>
					• 不记录您的浏览活动、下载内容或访问的网站；<br>
					• 不存储可以识别您身份的连接日志；<br>
					• 不监控或记录您通过VPN传输的数据。
				</p>

				<p>
					<b>信息共享</b><br>
					除以下情况外，我们不会向第三方分享您的个人信息：<br>
					• 遵守法律要求或法院命令；<br>
					• 保护我们的合法权益；<br>
					• 与我们的服务提供商合作（他们必须遵守同等的隐私标准）。
				</p>

				<p>
					<b>安全措施</b><br>
					我们采取多种措施保护您的信息安全：<br>
					• 使用加密技术保护数据传输；<br>
					• 实施访问控制限制员工访问用户数据；<br>
					• 定期进行安全审计和更新。
				</p>

				<p>
					<b>您的权利</b><br>
					您对自己的个人信息拥有以下权利：<br>
					• 访问和更正您的个人信息；<br>
					• 要求删除您的账户和相关数据。
				</p>

				<p>
					<b>拒绝营销通信</b><br>
					• 提出有关我们数据处理的问题。
				</p>

				<p>
					<b>政策更新</b><br>
					我们可能会不时更新本隐私政策，更新后的政策将在应用内和网站上公布。重大变更时，我们将通过电子邮件或应用内通知向您发出提醒。
				</p>

				<p>
					<b>联系我们</b><br>
					如果您对本隐私政策有任何疑问或顾虑，请通过以下方式联系我们：<br>
					电子邮件：admin@locklayertech.com<br>
					客服中心：应用内的"客服"部分
				</p>

				<p>
					最后更新日期：2025年3月1日
				</p>

			</div>
		</template>
		<template v-else>
			<div class="pp-title">Privacy Policy</div>
			<div class="pp-content">
				<p>Dear user, thank you for choosing our VPN service. This Privacy Policy aims to clearly explain how we collect, use, protect, and process your personal information. Please read the following carefully to understand our privacy protection measures.</p>
				<p>
					<b>Information Collection</b> <br>
					We may collect the following information:<br>
					• Account information: including your email address and payment information;<br>
					• Usage data: connection time, duration of use, selected servers;<br>
					• Device information: device type, operating system version, unique device identifiers;<br>
					• Network information: IP address, network connection status.
				</p>

				<p>
					<b>Information Use</b><br>
					We use the collected information for:<br>
					• Providing, maintaining, and improving our VPN service;<br>
					• Processing your subscriptions and payments;<br>
					• Technical support and customer service;<br>
					• Network security and fraud prevention.
				</p>

				<p>
					<b>Logging Policy</b><br>
					We implement a strict no-logs policy:<br>
					• We do not record your browsing activities, downloaded content, or websites visited;<br>
					• We do not store connection logs that can identify you;<br>
					• We do not monitor or record data transmitted through the VPN.
				</p>

				<p>
					<b>Information Sharing</b><br>
					We do not share your personal information with third parties, except in the following cases:<br>
					• To comply with legal requirements or court orders;<br>
					• To protect our legitimate interests;<br>
					• To collaborate with our service providers (who must adhere to equivalent privacy standards).
				</p>

				<p>
					<b>Security Measures</b><br>
					We take various measures to protect your information:<br>
					• Using encryption technology to protect data transmission;<br>
					• Implementing access controls to restrict employee access to user data;<br>
					• Conducting regular security audits and updates.
				</p>

				<p>
					<b>Your Rights</b><br>
					You have the following rights regarding your personal information:<br>
					• Access and correct your personal information;<br>
					• Request deletion of your account and related data;<br>
					• Opt out of marketing communications;<br>
					• Raise questions about our data processing.<br>
				</p>

				<p>
					<b>Policy Updates</b><br>
					We may update this Privacy Policy from time to time, and the updated policy will be published in the app and on our website. For significant changes, we will send you a reminder via email or in-app notification.
				</p>

				<p>
					<b>Contact Us</b><br>
					If you have any questions or concerns about this Privacy Policy, please contact us through:<br>
					Email: admin@locklayertech.com<br>
					Customer Service: in the "Support" section of the app
				</p>

				<p>
					Last updated: March 1, 2025
				</p>

			</div>
		</template>
	</div>
</template>
<script>

import {getDefaultLang} from '../utils/methods'
export default {
	name: "PrivacyPolicyPage",
	components: {},
	data() {
		return {
			reason: '',
			email: '',
			content: '',
			isSubmit: false,
			isSending: false
		}
	},
	async mounted() {

	},
	methods: {
		getDefaultLang () {
			return getDefaultLang()
		}
	}
}
</script>
<style scoped>
.pp-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2em 1em;
	color: #E6D5B8;
}

.pp-title{
	font-size: 2.2em;
}

p{
	text-align: start;
	font-size: 1.6em;
}
</style>