<template>
	<div class="emoji-picker" ref="emojiPicker">
		<div class="emoji-categories">
			<button
				v-for="(category, index) in categories"
				:key="index"
				@click="selectCategory(index)"
				:class="{ active: selectedCategory === index }"
			>
				{{ category.icon }}
			</button>
		</div>
		<div class="emoji-container">
			<button
				v-for="emoji in currentEmojis"
				:key="emoji"
				class="emoji-button"
				@click="selectEmoji(emoji)"
			>
				{{ emoji }}
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'EmojiPickerComponent',
	data() {
		return {
			selectedCategory: 0,
			categories: [
				{ icon: '😀', emojis: ['😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇', '🙂', '🙃', '😉', '😌', '😍', '🥰', '😘'] },
				{ icon: '👍', emojis: ['👍', '👎', '👌', '✌️', '🤞', '🤟', '🤘', '👏', '🙌', '👐', '🤲', '🤝', '👊', '👋'] },
				{ icon: '🐶', emojis: ['🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼', '🦁', '🐯', '🦄', '🐴', '🐮', '🦉'] },
				{ icon: '🍎', emojis: ['🍎', '🍐', '🍊', '🍋', '🍌', '🍉', '🍇', '🍓', '🍒', '🍑', '🥭', '🍍', '🥝', '🍅'] },
				{ icon: '❤️', emojis: ['❤️', '🧡', '💛', '💚', '💙', '💜', '🖤', '🤍', '🤎', '💔', '❣️', '💕', '💞', '💓'] },
			]
		}
	},
	computed: {
		currentEmojis() {
			return this.categories[this.selectedCategory].emojis;
		}
	},
	methods: {
		selectCategory(index) {
			this.selectedCategory = index;
		},
		selectEmoji(emoji) {
			this.$emit('select', emoji);
		}
	}
}
</script>

<style scoped>
.emoji-picker {
	background-color: white;
	border-radius: 8px;
	box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
	padding: 10px;
	z-index: 1000;
	border: 1px solid #eaeaea;

	overflow: hidden;
	width: auto;
}

.emoji-categories {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #eaeaea;
	padding-bottom: 8px;
	margin-bottom: 8px;
}

.emoji-categories button {
	background: none;
	border: none;
	font-size: 18px;
	cursor: pointer;
	padding: 5px;
	border-radius: 4px;
}

.emoji-categories button.active {
	background-color: #f0f0f0;
}

.emoji-container {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 5px;
	max-height: 180px;
	overflow-y: auto;
}

.emoji-button {
	background: none;
	border: none;
	font-size: 20px;
	padding: 5px;
	cursor: pointer;
	border-radius: 4px;
	transition: background-color 0.2s;
}

.emoji-button:hover {
	background-color: #f0f0f0;
}
</style>