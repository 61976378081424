// stores/languageStore.js
import { defineStore } from 'pinia'
import en from './en' // 假设en导入路径与原来相同

export const useLanguageStore = defineStore('language', {
	state: () => ({
		currentLanguage: null
	}),

	getters: {
		// 获取当前语言
		getCurrentLanguage() {
			return this.currentLanguage
		}
	},

	actions: {
		// 初始化语言设置
		initialize() {
			if (this.currentLanguage === null) {
				this.currentLanguage = this.getBrowserDefaultLanguage()
			}
		},

		// 获取浏览器默认语言
		getBrowserDefaultLanguage() {
			// 获取浏览器语言
			const browserLang = navigator.language || navigator.userLanguage || '';

			// 转换为小写并检查是否以'zh'开头
			const isChineseLanguage = browserLang.toLowerCase().startsWith('zh');

			// 如果是中文返回CN，否则返回EN
			return isChineseLanguage ? 'CN' : 'EN';
		},

		// 手动设置语言
		setLanguage(lang) {
			this.currentLanguage = lang;
			return this.currentLanguage;
		},

		localize(text, variables = {}) {
			// 获取基础文本（根据当前语言）
			let localizedText;

			// 如果是中文，直接使用中文文本
			if (this.currentLanguage === 'CN') {
				localizedText = text;
			} else {
				// 如果是英文，从翻译对象中查找对应翻译
				// 如果找不到翻译，返回原始中文文本
				localizedText = this.findTranslation(text) || text;
			}

			// 处理变量替换
			if (Object.keys(variables).length > 0) {
				// 遍历所有变量并替换
				Object.entries(variables).forEach(([key, value]) => {
					// 支持两种格式: {key} 或 ${key}
					const pattern1 = new RegExp(`{${key}}`, 'g');
					const pattern2 = new RegExp(`\\$\\{${key}\\}`, 'g');

					localizedText = localizedText
						.replace(pattern1, value)
						.replace(pattern2, value);
				});
			}

			return localizedText;
		},
		// 查找翻译
		findTranslation(text) {
			// 直接查找translations对象中是否有对应的键
			if (en[text] !== undefined) {
				return en[text];
			}

			// 如果没有直接匹配，返回原文
			return text;
		}
	}
})

// 创建一个辅助函数来快速获取store并初始化
export function getLanguageStore() {
	const langStore = useLanguageStore()
	langStore.initialize()
	return langStore
}