<template>
	<div class="join-us-container-mobile" v-if="isMobile">
		<div class="join-us-content-wrapper-mobile">
			<div class="join-us-left-mobile">
				<div class="join-us-header-mobile">{{ localize('现在加入星云加速推广计划') }}</div>
				<div class="join-us-header-1-mobile">{{ localize('立即开始构建稳定收入，让赚钱加倍轻松') }}</div>
				<img class="join-us-btn-mobile" src="../../images/joinUs/join_us_btn.svg" alt="join us"
				     @click="onJoinUs" v-if="langStore.getCurrentLanguage === 'CN'">
				<img class="join-us-btn-mobile" src="../../images/joinUs/join_us_btn_en.svg" alt="join us"
				     @click="onJoinUs" v-else>
			</div>
			<img class="share-icon-mobile" src="../../images/joinUs/share_icon.svg" alt="share">
		</div>

	</div>
	<div class="join-us-container" v-else>
		<div class="join-us-content-wrapper">
			<div class="join-us-left">
				<div class="join-us-header">{{ localize('现在加入星云加速推广计划') }}</div>
				<div class="join-us-header-1">{{ localize('立即开始构建稳定收入，让赚钱加倍轻松') }}</div>
				<img class="join-us-btn" src="../../images/joinUs/join_us_btn.svg" alt="join us" @click="onJoinUs"
				     v-if="langStore.getCurrentLanguage === 'CN'">
				<img class="join-us-btn" src="../../images/joinUs/join_us_btn_en.svg" alt="join us" @click="onJoinUs"
				     v-else>
			</div>
			<img class="share-icon" src="../../images/joinUs/share_icon.svg" alt="share">
		</div>

	</div>
</template>
<script>

export default {
	name: "JoinUsView",
	components: {},
	data() {
		return {}
	},
	inject: ['isMobile', 'langStore', 'localize'],
	methods: {
		onJoinUs() {
			window.open('https://t.me/xingyunjiasu', '_blank')
		}
	}
}
</script>
<style scoped>
.join-us-container {
	width: 100%;
	height: 100vh;
	box-sizing: border-box;
	position: relative;
	background: linear-gradient(121deg, rgba(240, 251, 255, 1) 0%, rgba(255, 245, 240, 1) 100%);

}

.join-us-container-mobile {
	width: 100%;
	min-height: 100vh;
	box-sizing: border-box;
	position: relative;
	background: linear-gradient(121deg, rgba(240, 251, 255, 1) 0%, rgba(255, 245, 240, 1) 100%);
	padding: 2em;
}

.share-icon {
	width: 30em;
	padding: 2em;
}

.share-icon-mobile {

	height: 30em;
	padding: 2em;
	box-sizing: border-box;
}

.join-us-left {
	display: flex;
	flex-direction: column;
	align-items: start;
	padding: 2em;
	width: 40em;
}

.join-us-left-mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.join-us-header {
	font-size: 2em;
	color: #353332;
	word-break: keep-all;
	width: 100%;
	text-align: start;
	font-weight: bold;
}

.join-us-header-mobile {
	font-size: 2em;
	color: #353332;
	word-break: keep-all;
	width: 100%;
	text-align: center;
	font-weight: bold;
}

.join-us-header-1 {
	font-size: 1.6em;
	color: #353332;
	margin-top: 0.4em;
	word-break: keep-all;
	width: 100%;
	text-align: start;
}

.join-us-header-1-mobile {
	font-size: 1.6em;
	color: #353332;
	margin-top: 0.4em;
	word-break: keep-all;
	width: 100%;
	text-align: center;
}

.join-us-btn {
	height: 4em;
	cursor: pointer;
	margin-top: 2em;
}

.join-us-btn:active {
	transform: scale(0.95);
}

.join-us-btn-mobile {
	height: 4em;
	margin-top: 2em;
}

.join-us-btn-mobile:active {
	transform: scale(0.95);
}

.join-us-content-wrapper {
	display: flex;
	flex-direction: row;
	gap: 2em;
	position: absolute;
	align-items: center;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -60%);
}

.join-us-content-wrapper-mobile {
	display: flex;
	flex-direction: column;
	gap: 2em;
	align-items: center;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -60%);
}
</style>