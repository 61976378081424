import { createI18n } from 'vue-i18n'
import zh from './zh'
import en from './en'

function getDefaultLang() {
	const browserLang = navigator.language || navigator.userLanguage
	const savedLang = localStorage.getItem('language')
	return savedLang || (browserLang.includes('zh') ? 'zh' : 'en')
}

const i18n = createI18n({
	legacy: true,  // 改为 true
	globalInjection: true,
	locale: getDefaultLang(),
	fallbackLocale: 'zh',
	messages: {
		zh,
		en
	}
})

export default i18n