<template>
	<router-view></router-view>
</template>

<script>
import {useI18n} from 'vue-i18n'
import {onMounted, onUnmounted, ref, watch, provide, readonly} from 'vue'
import {getLanguageStore} from '@/lang/langStore'

export default {
	name: 'App',
	components: {},
	data() {
		return {
			langStore: getLanguageStore()
		}
	},
	provide() {
		return {
			langStore: this.langStore,
			localize: this.localize
		}
	},
	methods: {
		localize(text, params) {
			return this.langStore.localize(text, params)
		}
	},
	setup() {
		const isMobile = ref(window.innerWidth < 900)

		const checkScreenSize = () => {
			isMobile.value = window.innerWidth < 900
		}

		// 使用防抖优化
		const debouncedCheckScreenSize = () => {
			if (window.resizeTimeout) clearTimeout(window.resizeTimeout)
			window.resizeTimeout = setTimeout(checkScreenSize, 200)
		}

		onMounted(() => {
			checkScreenSize() // 初始检查
			window.addEventListener('resize', debouncedCheckScreenSize)
		})

		onUnmounted(() => {
			window.removeEventListener('resize', debouncedCheckScreenSize)
		})

		// 提供给所有子组件
		provide('isMobile', readonly(isMobile))

		return {isMobile}
	},
	watch: {
		'$i18n.locale': {
			handler(newLocale) {
				document.title = this.$t('网页标题')
			},
			immediate: true
		}
	}
}
</script>

<style>
#app {
	font-family: PingFang SC, system-ui, Arial, Source Han Sans, Microsoft YaHei, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	background-color: #ffffff;
	width: 100%;
	height: 100%;
	position: fixed;
	background-image: url('./images/matrix_bg.png');
	overflow-y: scroll;
	overflow-x: hidden;
}

body {
	margin: 0;

	font-family: PingFang SC, system-ui, Arial, Source Han Sans, Microsoft YaHei, sans-serif

}

.no-select {
	-webkit-user-select: none; /* Safari, Chrome, Opera, Samsung */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE/Edge */
	user-select: none; /* 标准语法 */
}

.disable-select {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

.download-confirm-btn {
	background: linear-gradient(121deg, rgba(255, 153, 36, 1) 0%, rgba(255, 131, 51, 1) 77%, rgba(255, 51, 0, 1) 100%);
	border: none;
	color: #ffffff;
	font-size: 1em;
	padding: 0.2em 1em;
	border-radius: 0.2em;
	margin: 0.4em;
	cursor: pointer;
}

.download-cancel-btn {
	background-color: #000000;
	border: none;
	color: #ffffff;
	font-size: 1em;
	padding: 0.2em 1em;
	border-radius: 0.2em;
	margin: 0.4em;
	cursor: pointer;
}

.nb-confirm-dialog-text {
	color: #ff8900;
	padding: 0.4em 0;
	max-width: 30em;
}

.nb-confirm-btn-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.nb-confirm-btn-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.nb-btn:active {
	transform: scale(0.95);
}

.nb-btn {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

</style>
