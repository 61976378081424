<template>
	<div class="nb-refer-download-container">

		<ModalContainer modalTitle="请注册账号后下载" @close="onRegisterModalClose" v-if="showRegisterModal === true">
			<RegisterModalContent @registerSuccess="onRegisterAccountSuccess" regOrLogin="REG"></RegisterModalContent>
		</ModalContainer>
		<ModalContainer modalTitle="" :showClose="false" v-if="showConfirmDialog === true">
			<div class="nb-confirm-btn-container">
				<div class="nb-confirm-dialog-text">{{ this.confirmModalTitle }}</div>
				<div class="nb-confirm-btn-row">
					<div class="nb-confirm-btn" @click="onConfirmBtnClick">确认</div>
					<div class="nb-cancel-btn" @click="onCancelBtnClick">取消</div>
				</div>

			</div>
		</ModalContainer>
		<div class="nb-refer-download-wrapper">
			<div class="nb-refer-download-title-container">
				<img src="../images/sl_logo.svg" alt="logo" class="nb-refer-download-logo">
				<div class="nb-refer-download-title">星云加速器</div>
			</div>
			<div class="nb-refer-download-text">
				立即下载，开始免费使用
			</div>
			<div class="nb-refer-download-section">
				<div class="nb-refer-download-card">
					<img src="../images/os_win.svg" alt="windows" class="nb-refer-download-image">
					<div class="nb-refer-download-text">Windows</div>
					<div class="nb-refer-download-btn" @click="onWindowsDownloadClicked">
						下载
					</div>
				</div>
				<div class="nb-refer-download-card">
					<img src="../images/os_and.svg" alt="android" class="nb-refer-download-image">
					<div class="nb-refer-download-text">Android</div>
					<div class="nb-refer-download-btn" @click="onAndroidDownloadClicked">
						下载
					</div>
				</div>
				<div class="nb-refer-download-card">
					<img src="../images/os_ios.svg" alt="iphone" class="nb-refer-download-image">
					<div class="nb-refer-download-text">iPhone</div>
					<div class="nb-refer-download-btn" @click="onIosDownloadClicked">
						下载
					</div>
				</div>
				<div class="nb-refer-download-card">
					<img src="../images/os_macos.svg" alt="macos" class="nb-refer-download-image">
					<div class="nb-refer-download-text">iMac</div>
					<div class="nb-refer-download-btn" @click="onMacDownloadClicked">
						下载
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {
	getAndroidDownloadLink,
	getClientId,
	getUrlId,
	getUrlType,
	getWindowsDownloadLink,
	hideLoading,
	isSessionValid,
	showLoading,
	showToast,
	startDownload
} from "@/utils/methods";
import ModalContainer from "@/components/ModalContainer";
import RegisterModalContent from "@/components/RegisterModalContent";

export default {
	name: "ReferDownloadPage",
	components: {
		ModalContainer,
		RegisterModalContent
	},
	data() {
		return {
			showRegisterModal: false,
			referCode: null,
			afterRegisterSuccess: null,
			showConfirmDialog: false,
			confirmModalTitle: '',
			confirmCallback: null
		};
	},
	async mounted() {
		this.referCode = this.$route.query.code
		this.$store.dispatch('setReferCode', {
			referCode: this.referCode
		})
		const result = await isSessionValid()
		if (result.err === false) {
			this.$store.dispatch('setLoginState', {isLogin: true, sessionExpireTimestamp: result.expiredAtTimestamp})
			this.$store.dispatch('setUserInfo', {email: result.email, planExpireTime: result.planExpireTime})
		}
	},
	methods: {
		async onAndroidDownloadClicked() {
			if (this.$store.state.isLogin !== true) {
				this.showRegisterModal = true
				this.afterRegisterSuccess = this.onAndroidDownloadClicked
				return
			}
			try {
				showLoading()
				const downloadLink = await getAndroidDownloadLink()
				const userAgent = window.navigator.userAgent
				fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_android_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`);
				showToast('开始下载...')
				startDownload(downloadLink)
			} catch (e) {
				showToast('下载失败，请重试')
			} finally {
				hideLoading()
			}
		},
		async onWindowsDownloadClicked() {
			if (this.$store.state.isLogin !== true) {
				this.showRegisterModal = true
				this.afterRegisterSuccess = this.onWindowsDownloadClicked
				return
			}
			try {
				showLoading()
				const downloadLink = await getWindowsDownloadLink()
				const userAgent = window.navigator.userAgent
				fetch(`https://payment.private-tunnel.com:3099/visit/fromweb?visitType=official_win_download&userAgent=${userAgent}&clientId=${getClientId()}&url=${window.location.href}&type=${getUrlType()}&recordId=${getUrlId()}`)
				showToast('开始下载...')
				startDownload(downloadLink)
			} catch (e) {
				showToast('下载失败，请重试')
			} finally {
				hideLoading()
			}
		},
		onIosDownloadClicked() {
			this.confirmModalTitle = '接下来您将跳转到苹果应用商店(App Store)。由于该应用仅在海外区域上架，您需要登陆海外地区苹果账号(Apple ID)才能成功下载安装。'
			this.showConfirmDialog = true
			this.confirmCallback = () => {
				this.showConfirmDialog = false
				window.open('https://apps.apple.com/us/app/%E6%98%9F%E5%85%89%E5%8A%A0%E9%80%9F/id6742536670?l=zh-Hans-CN', '_blank');
			}
		},
		onMacDownloadClicked() {
			this.confirmModalTitle = 'IOS或MAC用户请使用机场，点击“确认”跳转星云机场主页。'
			this.showConfirmDialog = true
			this.confirmCallback = () => {
				this.showConfirmDialog = false
				window.open('https://www.alphacloud.cc', '_blank');
			}
		},
		onRegisterModalClose() {
			this.showRegisterModal = false
		},
		onRegisterAccountSuccess() {
			this.showRegisterModal = false
			if (this.afterRegisterSuccess) {
				this.afterRegisterSuccess()
			}
		},
		onConfirmBtnClick() {
			if (this.confirmCallback) {
				this.confirmCallback()
			}
		},
		onCancelBtnClick() {
			this.showConfirmDialog = false
		}
	}
}
</script>

<style scoped>
.nb-refer-download-container {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: start;
}

.nb-refer-download-wrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: start;
}

.nb-refer-download-logo {
	width: 2.4em;
	margin-right: 0.4em;
}

.nb-refer-download-title {
	color: #ff8900;
	font-size: 2.4em;
	line-height: 2.4em;
}

.nb-refer-download-title-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 0.4em;
}

.nb-refer-download-text {
	color: #e45826;
	font-size: 2em;
	margin-top: 0.4em;
}

.nb-refer-download-section {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 4em;
	padding: 6em 0;
}

.nb-refer-download-image {
	height: 8em;
	max-width: 8em;
}

.nb-refer-download-btn {
	background-color: #e45826;
	color: #1f1f35;
	width: fit-content;
	font-size: 1.6em;
	padding: 0.2em 0.8em;
	border-radius: 0.2em;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 0.4em;
	cursor: pointer;
}

.nb-refer-download-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}


@media (max-width: 900px) {

	.nb-refer-download-section {
		grid-template-columns: repeat(2, 1fr) !important;
		grid-gap: 4em !important;
		padding: 2em;
	}
}
</style>