import { io } from "socket.io-client";

import {getFingerprint} from "../utils/methods"

class SocketService {
	constructor() {
		this.socket = null;
		this.isConnected = false;
		this.url = null;
		this.options = null;
		this.inactivityTimer = null;
		this.inactivityTimeout = 3 * 60 * 1000; // 3分钟超时时间（毫秒）
	}

	// 初始化连接
	async setupSocketConnection(url = "http://localhost:3000") {
		this.url = url;
		this.options = {
			reconnectionAttempts: 5,  // 重连尝试次数
			reconnectionDelay: 1000,  // 重连延迟时间（毫秒）
			transports: ["websocket"],  // 优先使用WebSocket传输
			autoConnect: true // 自动连接
		};

		// console.log(`websocket url: ${url}`);
		await this._connectSocket();
		return this.socket;
	}

	// 创建实际的Socket连接
	async _connectSocket() {
		if (this.socket) {
			// 如果已经有socket实例但断开了连接，尝试重新连接
			if (!this.isConnected) {
				this.socket.connect();
				return;
			}
			return;  // 已经连接，不需要重新创建
		}

		const fp = await getFingerprint()

		// 方法1：使用auth选项传递fp
		this.options.auth = {
			...this.options.auth,
			fp: fp  // 假设this.fingerprint中存储了指纹
		};


		this.socket = io(this.url, this.options);

		// 设置基本事件监听
		this.socket.on("connect", () => {
			console.log("已连接到WebSocket服务器");
			this.isConnected = true;
			this._resetInactivityTimer();  // 连接成功后开始计时
		});

		this.socket.on("disconnect", (reason) => {
			console.log(`WebSocket连接已断开: ${reason}`);
			this.isConnected = false;
			this._clearInactivityTimer();  // 断开连接时清除计时器
		});

		this.socket.on("connect_error", (error) => {
			console.log(`连接错误: ${error.message}`);
		});

		// 监听所有收到的事件，用于重置计时器
		this.socket.onAny(() => {
			this._resetInactivityTimer();
		});
	}

	// 重置不活动计时器
	_resetInactivityTimer() {
		this._clearInactivityTimer();
		this.inactivityTimer = setTimeout(() => {
			console.log("由于3分钟内无活动，断开WebSocket连接");
			this.disconnect();
		}, this.inactivityTimeout);
	}

	// 清除不活动计时器
	_clearInactivityTimer() {
		if (this.inactivityTimer) {
			clearTimeout(this.inactivityTimer);
			this.inactivityTimer = null;
		}
	}

	getSocket() {
		return this.socket;
	}

	// 关闭连接
	disconnect() {
		this._clearInactivityTimer();
		if (this.socket) {
			this.socket.disconnect();
			this.isConnected = false;
		}
	}

	// 确保连接已建立
	_ensureConnection() {
		if (!this.socket || !this.isConnected) {
			this._connectSocket();

			// 等待连接建立
			if (!this.isConnected) {
				console.log("正在重新建立连接...");
				// 注意：这里不会等待连接完成，实际发送可能失败
				// 如果需要等待连接建立，应使用Promise和回调
			}
		}
	}

	// 发送消息
	emit(event, data) {
		this._ensureConnection();  // 确保连接已建立

		if (this.socket && this.isConnected) {
			this.socket.emit(event, data);
			this._resetInactivityTimer();  // 发送消息后重置计时器
		} else {
			console.warn("Socket未连接，消息将在连接建立后发送");
			// 可选：将消息加入队列，等待连接建立后发送
		}
	}

	// 注册事件监听
	on(event, callback) {
		this._ensureConnection();  // 确保连接已建立

		if (this.socket) {
			// 包装回调函数，使其重置计时器
			const wrappedCallback = (...args) => {
				this._resetInactivityTimer();
				callback(...args);
			};
			this.socket.on(event, wrappedCallback);
		}
	}

	// 移除事件监听
	off(event, callback) {
		if (this.socket) {
			this.socket.off(event, callback);
		}
	}

	// 获取连接状态
	getConnectionStatus() {
		return this.isConnected;
	}

	// 设置自动断开时间（毫秒）
	setInactivityTimeout(timeout) {
		this.inactivityTimeout = timeout;
		if (this.isConnected) {
			this._resetInactivityTimer();  // 如果已连接，重置计时器
		}
	}
}

// 创建单例
const socketService = new SocketService();
export default socketService;