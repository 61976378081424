<template>
	<template v-if="isMobile">
		<div class="main-container-mobile">
			<div class="landing-view-container-mobile">
				<div class="left-size-view-mobile">
					<img class="landing-main-text-mobile" src="../../images/mainView/land_main_text.svg" alt="main text" v-if="langStore.getCurrentLanguage === 'CN'">
					<img class="landing-main-text-mobile" src="../../images/mainView/land_main_text_en.svg" alt="main text" v-else>
					<div class="download-btn-grid-container-mobile">
						<img class="app-download-btn" :src="iosDownloadBtn" alt="ios btn"
						     @click="onIosDownload" v-if="langStore.getCurrentLanguage === 'CN'">
						<img class="app-download-btn" :src="iosDownloadBtnEn" alt="ios btn"
						     @click="onIosDownload" v-else>
						<img class="app-download-btn" :src="androidDownloadBtn" alt="android btn"
						     @click="onAndroidDownload" v-if="langStore.getCurrentLanguage === 'CN'">
						<img class="app-download-btn" :src="androidDownloadBtnEn" alt="android btn"
						     @click="onAndroidDownload" v-else>
						<img class="app-download-btn" :src="winDownloadBtn" alt="ios btn"
						     @click="onMacDownload" v-if="langStore.getCurrentLanguage === 'CN'">
						<img class="app-download-btn" :src="winDownloadBtnEn" alt="ios btn"
						     @click="onMacDownload" v-else>
						<img class="app-download-btn" :src="macDownloadBtn" alt="win btn"
						     @click="onWindowsDownload" v-if="langStore.getCurrentLanguage === 'CN'">
						<img class="app-download-btn" :src="macDownloadBtnEn" alt="win btn"
						     @click="onWindowsDownload" v-else>
					</div>
				</div>
				<div class="right-size-view-mobile">
					<img class="screen-view-mobile" src="../../images/mainView/main_screen_view.webp"
					     alt="main screen view">
				</div>
				<img class="main-view-bg-mobile" src="../../images/mainView/main_view_bg.svg" alt="main view bg">
			</div>
			<div class="divider" />
			<div class="feature-container-mobile">
				<div class="feature-main-title-container-mobile">
					<div class="feature-main-title">{{localize('别具一格的网络服务')}}</div>
					<div class="feature-main-title-highlight">{{localize('为您带来畅快体验')}}</div>
				</div>

				<div class="feature-grid-container-mobile">
					<div class="feature-item-container">
						<img class="feature-icon" :src="featureNodesIcon" alt="nodes">
						<div class="feature-title-mobile">{{localize('节点覆盖全球')}}</div>
						<div class="feature-detail-mobile">{{localize('高速节点遍布全球20+国家')}}</div>
						<div class="feature-detail-mobile">{{localize('极速、稳定、隐私')}}</div>
					</div>
					<div class="feature-item-container">
						<img class="feature-icon" :src="featurePrivacyIcon" alt="privacy">
						<div class="feature-title-mobile">{{localize('无追踪保障')}}</div>
						<div class="feature-detail-mobile">{{localize('不保存任何敏感信息')}}</div>
						<div class="feature-detail-mobile">{{localize('全面守护隐私')}}</div>
					</div>
					<div class="feature-item-container">
						<img class="feature-icon" :src="featureUnlockIcon" alt="nodes">
						<div class="feature-title-mobile">{{localize('支持AI与流媒体')}}</div>
						<div class="feature-detail-mobile">{{localize('跨越国境')}}</div>
						<div class="feature-detail-mobile">{{localize('访问专属内容')}}</div>
					</div>
					<div class="feature-item-container">
						<img class="feature-icon" :src="featureAccountIcon" alt="nodes">
						<div class="feature-title-mobile">{{localize('非实名账号')}}</div>
						<div class="feature-detail-mobile">{{localize('支持非实名账号登录')}}</div>
						<div class="feature-detail-mobile">{{localize('保护用户身份信息')}}</div>
					</div>
					<div class="feature-item-container">
						<img class="feature-icon" :src="featureOsIcon" alt="nodes">
						<div class="feature-title-mobile">{{localize('全客户端支持')}}</div>
						<div class="feature-detail-mobile">{{localize('支持主流操作系统')}}</div>
						<div class="feature-detail-mobile">{{localize('一键开启高速冲浪')}}</div>
					</div>
					<div class="feature-item-container">
						<img class="feature-icon" :src="featureSupportIcon" alt="nodes">
						<div class="feature-title-mobile">{{localize('快速的售后支持')}}</div>
						<div class="feature-detail-mobile">{{localize('实时在线客服')}}</div>
						<div class="feature-detail-mobile">{{localize('提供持续友好的服务')}}</div>
					</div>

					<div class="feature-item-container">
						<img class="feature-icon" :src="featureTrafficIcon" alt="nodes">
						<div class="feature-title-mobile">{{localize('无流量限制')}}</div>
						<div class="feature-detail-mobile">{{localize('不限速，不限流')}}</div>
						<div class="feature-detail-mobile">{{localize('带来无比丝滑的浏览体验')}}</div>
					</div>

					<div class="feature-item-container">
						<img class="feature-icon" :src="featureEncryptIcon" alt="nodes">
						<div class="feature-title-mobile">{{localize('数据加密')}}</div>
						<div class="feature-detail-mobile">{{localize('企业级加密技术')}}</div>
						<div class="feature-detail-mobile">{{localize('保障用户信息安全')}}</div>
					</div>

					<div class="feature-item-container">
						<img class="feature-icon" :src="featureIpIcon" alt="nodes">
						<div class="feature-title-mobile">{{localize('住宅IP供应')}}</div>
						<div class="feature-detail-mobile">{{localize('内含多国住宅IP')}}</div>
						<div class="feature-detail-mobile">{{localize('轻松解锁专业用途')}}</div>
					</div>

				</div>

			</div>
		</div>
	</template>

	<template v-else>
		<div class="main-container">
			<div class="landing-view-container">
				<div class="left-size-view">
					<img class="landing-main-text" src="../../images/mainView/land_main_text.svg" alt="main text" v-if="langStore.getCurrentLanguage === 'CN'">
					<img class="landing-main-text" src="../../images/mainView/land_main_text_en.svg" alt="main text" v-else>
					<div class="download-btn-grid-container">
						<img class="app-download-btn" :src="iosDownloadBtn" alt="ios btn"
						     @click="onIosDownload" v-if="langStore.getCurrentLanguage === 'CN'">
						<img class="app-download-btn" :src="iosDownloadBtnEn" alt="ios btn"
						     @click="onIosDownload" v-else>
						<img class="app-download-btn" :src="androidDownloadBtn" alt="android btn"
						     @click="onAndroidDownload"  v-if="langStore.getCurrentLanguage === 'CN'">
						<img class="app-download-btn" :src="androidDownloadBtnEn" alt="android btn"
						     @click="onAndroidDownload"  v-else>
						<img class="app-download-btn" :src="macDownloadBtn" alt="mac btn"
						     @click="onMacDownload" v-if="langStore.getCurrentLanguage === 'CN'">
						<img class="app-download-btn" :src="macDownloadBtnEn" alt="mac btn"
						     @click="onMacDownload" v-else>
						<img class="app-download-btn" :src="winDownloadBtn" alt="ios btn"
						     @click="onWindowsDownload" v-if="langStore.getCurrentLanguage === 'CN'">
						<img class="app-download-btn" :src="winDownloadBtnEn" alt="ios btn"
						     @click="onWindowsDownload" v-else>
					</div>
				</div>
				<div class="right-size-view">
					<img class="screen-view" src="../../images/mainView/main_screen_view.webp" alt="main screen view">
				</div>
				<img class="main-view-bg" src="../../images/mainView/main_view_bg.svg" alt="main view bg">
			</div>

			<div class="divider" />
			<div class="feature-container">
				<div class="feature-main-title-container">
					<div class="feature-main-title">{{localize('别具一格的网络服务')}}</div>
					<div class="feature-main-title-highlight">{{localize('为您带来畅快体验')}}</div>
				</div>

				<div class="feature-grid-container">
					<div class="feature-item-container">
						<img class="feature-icon" :src="featureNodesIcon" alt="nodes">
						<div class="feature-title">{{localize('节点覆盖全球')}}</div>
						<div class="feature-detail">{{localize('高速节点遍布全球20+国家')}}</div>
						<div class="feature-detail">{{localize('极速、稳定、隐私')}}</div>
					</div>
					<div class="feature-item-container">
						<img class="feature-icon" :src="featurePrivacyIcon" alt="privacy">
						<div class="feature-title">{{localize('无追踪保障')}}</div>
						<div class="feature-detail">{{localize('不保存任何敏感信息')}}</div>
						<div class="feature-detail">{{localize('全面守护隐私')}}</div>
					</div>
					<div class="feature-item-container">
						<img class="feature-icon" :src="featureUnlockIcon" alt="nodes">
						<div class="feature-title">{{localize('支持AI与流媒体')}}</div>
						<div class="feature-detail">{{localize('节点覆盖全球')}}</div>
						<div class="feature-detail">{{localize('访问专属内容')}}</div>
					</div>
					<div class="feature-item-container">
						<img class="feature-icon" :src="featureAccountIcon" alt="nodes">
						<div class="feature-title">{{localize('非实名账号')}}</div>
						<div class="feature-detail">{{localize('支持非实名账号登录')}}</div>
						<div class="feature-detail">{{localize('保护用户身份信息')}}</div>
					</div>
					<div class="feature-item-container">
						<img class="feature-icon" :src="featureOsIcon" alt="nodes">
						<div class="feature-title">{{localize('全客户端支持')}}</div>
						<div class="feature-detail">{{localize('支持主流操作系统')}}</div>
						<div class="feature-detail">{{localize('节点覆盖全球')}}</div>
					</div>
					<div class="feature-item-container">
						<img class="feature-icon" :src="featureSupportIcon" alt="nodes">
						<div class="feature-title">{{localize('快速的售后支持')}}</div>
						<div class="feature-detail">{{localize('实时在线客服')}}</div>
						<div class="feature-detail">{{localize('提供持续友好的服务')}}</div>
					</div>

					<div class="feature-item-container">
						<img class="feature-icon" :src="featureTrafficIcon" alt="nodes">
						<div class="feature-title">{{localize('无流量限制')}}</div>
						<div class="feature-detail">{{localize('不限速，不限流')}}</div>
						<div class="feature-detail">{{localize('带来无比丝滑的浏览体验')}}</div>
					</div>

					<div class="feature-item-container">
						<img class="feature-icon" :src="featureEncryptIcon" alt="nodes">
						<div class="feature-title">{{localize('数据加密')}}</div>
						<div class="feature-detail">{{localize('企业级加密技术')}}</div>
						<div class="feature-detail">{{localize('保障用户信息安全')}}</div>
					</div>

					<div class="feature-item-container">
						<img class="feature-icon" :src="featureIpIcon" alt="nodes">
						<div class="feature-title">{{localize('住宅IP供应')}}</div>
						<div class="feature-detail">{{localize('内含多国住宅IP')}}</div>
						<div class="feature-detail">{{localize('轻松解锁专业用途')}}</div>
					</div>

				</div>

			</div>
		</div>
	</template>

	<ModalContainer modalTitle="" :showClose="false" v-if="showConfirmDialog === true">
		<div class="download-confirm-btn-container">
			<div class="download-confirm-dialog-text">接下来您将跳转到苹果应用商店(App
				Store)。由于该应用仅在海外区域上架，您需要登陆海外地区苹果账号(Apple ID)才能成功下载安装。
			</div>
			<div class="download-confirm-btn-row">
				<div class="download-cancel-btn" @click="onCancelBtnClick">{{ $t("取消") }}</div>
				<div class="download-confirm-btn" @click="onConfirmBtnClick">{{ $t("确认跳转") }}</div>
			</div>

		</div>
	</ModalContainer>
</template>
<script>
import {reportVisit, startAndroidDownload, startMacDownload, startWindowsDownload} from "@/utils/methods";
import ModalContainer from "@/components/ModalContainer.vue";

import featureNodesIcon from '@/images/mainView/features/feature_nodes_icon.svg'
import featurePrivacyIcon from '@/images/mainView/features/feature_privacy_icon.svg'
import featureUnlockIcon from '@/images/mainView/features/feature_unlock_icon.svg'
import featureAccountIcon from '@/images/mainView/features/feature_account_icon.svg'
import featureOsIcon from '@/images/mainView/features/feature_os_icon.svg'
import featureSupportIcon from '@/images/mainView/features/feature_support_icon.svg'
import featureTrafficIcon from '@/images/mainView/features/feature_traffic_icon.svg'
import featureEncryptIcon from '@/images/mainView/features/feature_encrypt_icon.svg'
import featureIpIcon from '@/images/mainView/features/feature_ip_icon.svg'
import iosDownloadBtn from '@/images/mainView/ios_download_btn.svg'
import iosDownloadBtnEn from '@/images/mainView/ios_download_btn_en.svg'
import androidDownloadBtn from '@/images/mainView/android_download_btn.svg'
import androidDownloadBtnEn from '@/images/mainView/android_download_btn_en.svg'
import winDownloadBtn from '@/images/mainView/win_download_btn.svg'
import winDownloadBtnEn from '@/images/mainView/win_download_btn_en.svg'
import macDownloadBtn from '@/images/mainView/mac_download_btn.svg'
import macDownloadBtnEn from '@/images/mainView/mac_download_btn_en.svg'
// import langHelper from "@/lang/langHelper";

import { getLanguageStore } from '@/lang/langStore'

export default {
	name: "MainView",
	components: {ModalContainer},
	inject: ['isMobile', 'langStore', 'localize'],
	data() {
		return {
			isDownloading: false,
			showConfirmDialog: false,
			featureNodesIcon,
			featurePrivacyIcon,
			featureUnlockIcon,
			featureAccountIcon,
			featureOsIcon,
			featureSupportIcon,
			featureTrafficIcon,
			featureEncryptIcon,
			featureIpIcon,
			iosDownloadBtn,
			androidDownloadBtn,
			winDownloadBtn,
			macDownloadBtn,
			iosDownloadBtnEn,
			androidDownloadBtnEn,
			winDownloadBtnEn,
			macDownloadBtnEn
		}
	},
	methods: {
		async onIosDownload() {

			this.showConfirmDialog = true
		},
		async onAndroidDownload() {
			if (this.isDownloading) {
				return
			}

			this.isDownloading = true
			await startAndroidDownload()
			this.isDownloading = false
		},
		async onMacDownload() {
			if (this.isDownloading) {
				return
			}

			this.isDownloading = true
			await startMacDownload()
			this.isDownloading = false
		},
		async onWindowsDownload() {
			if (this.isDownloading) {
				return
			}

			this.isDownloading = true
			await startWindowsDownload()
			this.isDownloading = false
		},
		async onConfirmBtnClick() {
			this.showConfirmDialog = false
			window.open('https://apps.apple.com/us/app/%E6%98%9F%E5%85%89%E5%8A%A0%E9%80%9F/id6742536670?l=zh-Hans-CN', '_blank');
			await reportVisit('ios_download')
		},
		onCancelBtnClick() {
			this.showConfirmDialog = false
		},
	}
}
</script>

<style scoped>
.landing-view-container {
	display: flex;
	flex-direction: row;
	width: 100%;
	box-sizing: border-box;
	background: linear-gradient(121deg, rgba(229, 237, 255, 1) 0%, rgba(255, 250, 243, 1) 100%);
	align-items: center;
	justify-content: center;
	height: 100vh;
	position: relative;
}

.main-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
}

.feature-container {
	display: flex;
	width: 100%;
	box-sizing: border-box;
	flex-direction: column;
	align-items: center;
	padding: 8em 0;
	background-color: #FAF7F6;
}

.feature-container-mobile {
	display: flex;
	width: 100%;
	box-sizing: border-box;
	flex-direction: column;
	align-items: center;
	padding: 4em 0;
	background-color: #FAF7F6;
}


.feature-grid-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr; /* 两列，宽度相等 */
	grid-template-rows: 1fr 1fr 1fr; /* 两行，高度相等 */
	gap: 4em 6em;
}

.feature-grid-container-mobile {
	display: grid;
	grid-template-columns: 1fr 1fr; /* 两列，宽度相等 */
	grid-template-rows: 1fr 1fr 1fr; /* 两行，高度相等 */
	gap: 2em 1em;
}

.feature-item-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.feature-title {
	color: #B77757;
	font-size: 1.8em;
	font-weight: bold;
	margin-bottom: 0.4em;
}

.feature-title-mobile {
	color: #B77757;
	font-size: 1.6em;
	font-weight: bold;
	margin-bottom: 0.4em;
}

.feature-detail {
	color: #706C67;
	font-size: 1.6em;
	max-width: 14em;
}

.feature-detail-mobile{
	color: #706C67;
	font-size: 1.2em;
}

.feature-main-title-container{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-bottom: 2em;
}

.feature-main-title-container-mobile{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 2em;
}

.feature-main-title {
	font-size: 2.2em;
	font-weight: bold;
	color: #353332;
}

.feature-main-title-highlight{
	color: #B76E57;
	font-size: 2.2em;
	font-weight: bold;
	background-color: #f9eee7;
	padding: 0.1em 0.2em;
}

.main-container-mobile {
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
	background: linear-gradient(121deg, rgba(229, 237, 255, 1) 0%, rgba(255, 250, 243, 1) 100%);
	align-items: center;
	justify-content: space-evenly;
	padding: 0;
	position: relative;
	min-height: 100vh;
}

.landing-view-container-mobile{
	display: flex;
	flex-direction: column;
	width: 100%;
	box-sizing: border-box;
	background: linear-gradient(121deg, rgba(229, 237, 255, 1) 0%, rgba(255, 250, 243, 1) 100%);
	align-items: center;
	justify-content: space-evenly;
	padding: 0;
	position: relative;
	min-height: 100vh;
}

.left-size-view {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-right: 2em;
	z-index: 1;
}

.left-size-view-mobile {
	display: flex;
	flex-direction: column;
	align-items: center;
	z-index: 1;
	width: 100%;
}

.landing-main-text {
	width: 45em;
}

.landing-main-text-mobile {
	width: 90%;
}

.download-btn-grid-container {
	display: grid;
	grid-template-columns: 1fr 1fr; /* 两列，宽度相等 */
	grid-template-rows: 1fr 1fr; /* 两行，高度相等 */
	width: 100%;
	max-width: 45em; /* 限制最大宽度与 landing-main-text 一致 */
	box-sizing: border-box;
	gap: 3em;
	margin-top: 4em;
}

.download-btn-grid-container-mobile {
	display: grid;
	grid-template-columns: 1fr 1fr; /* 两列，宽度相等 */
	grid-template-rows: 1fr 1fr; /* 两行，高度相等 */
	width: 90%;
	max-width: 45em; /* 限制最大宽度与 landing-main-text 一致 */
	box-sizing: border-box;
	gap: 3em;
	margin-top: 4em;
}

.app-download-btn {
	width: 100%;
	cursor: pointer;
	box-sizing: border-box;
}

.app-download-btn:active {
	transform: scale(0.95);
}

.screen-view {
	width: 45em;
}

.screen-view-mobile {
	width: 100%;
}

.right-size-view {
	padding-left: 2em;
	z-index: 1;
}

.right-size-view-mobile {
	z-index: 1;
	width: 90%;
}

.main-view-bg {
	position: absolute;
	top: 0;
	right: -20em;
	z-index: 0;
	height: 100%;
	opacity: 0.1;
}

.main-view-bg-mobile {
	position: absolute;
	top: 0;
	right: -6em;
	z-index: 0;
	opacity: 0.1;
	height: 50%;
}

.download-confirm-btn-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.download-confirm-dialog-text {
	color: #353332;
	padding: 0.4em 0;
	max-width: 30em;
}

.download-confirm-btn-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.feature-icon{
	width: 8em;
	padding: 1em;
	filter: drop-shadow(0 4px 6px rgba(210, 90, 0, 0.1));
}

.divider {
	position: relative;
	height: 1px;
	width: 100%;
	margin: 0;
	padding: 0;
	background: linear-gradient(124deg, rgba(255,255,255,0) 0%, rgba(236,149,67,0.4850533963585434) 50%, rgba(255,255,255,0) 100%);
}
</style>