<template>
	<div class="download-detail-container-mobile" v-if="isMobile">
		<div class="download-detail-left-container-mobile">
			<div class="download-main-text">{{localize('iPhone/iPad客户端')}}</div>
			<div class="download-secondary-text-mobile">{{localize('从App Store下载客户端')}}</div>
			<div class="download-secondary-text-mobile">{{localize('需要您使用其他国家或地区Apple ID账号')}}</div>
			<!--				<div class="download-secondary-text">需要您使用其他国家或地区Apple ID账号</div>-->
			<div class="download-secondary-text-mobile">{{localize('本应用已经过苹果App Store安全审核')}}</div>
			<div class="download-secondary-text-mobile">{{localize('无后门无敏感数据采集')}}</div>

			<img class="app-download-btn" src="../../../images/downloadView/ios_download_btn.svg"
			     alt="ios download btn" @click="onIosDownloadClicked" v-if="langStore.getCurrentLanguage === 'CN'">
			<img class="app-download-btn" src="../../../images/downloadView/app_store_download_btn_en.svg"
			     alt="ios download btn" @click="onIosDownloadClicked" v-else>
		</div>

		<div class="download-detail-right-container-mobile">
			<img class="ios-screenshot-mobile" src="../../../images/downloadView/ios_screenshot_1.webp" alt="ios screenshot 1">
			<img class="ios-screenshot-mobile" src="../../../images/downloadView/ios_screenshot_2.webp" alt="ios screenshot 2">
		</div>
	</div>
	<div class="download-detail-container" v-else>
		<div class="download-detail-left-container">
			<div class="download-main-text">{{localize('iPhone/iPad客户端')}}</div>
			<div class="download-secondary-text">{{localize('从App Store下载客户端')}}</div>
			<div class="download-secondary-text">{{localize('需要您使用其他国家或地区Apple ID账号')}}</div>
			<!--				<div class="download-secondary-text">需要您使用其他国家或地区Apple ID账号</div>-->
			<div class="download-secondary-text">{{localize('本应用已经过苹果App Store安全审核')}}</div>
			<div class="download-secondary-text">{{localize('无后门无敏感数据采集')}}</div>

			<img class="app-download-btn" src="../../../images/downloadView/ios_download_btn.svg"
			     alt="ios download btn" @click="onIosDownloadClicked" v-if="langStore.getCurrentLanguage === 'CN'">
			<img class="app-download-btn" src="../../../images/downloadView/app_store_download_btn_en.svg"
			     alt="ios download btn" @click="onIosDownloadClicked" v-else>
		</div>

		<div class="download-detail-right-container">
			<img class="ios-screenshot" src="../../../images/downloadView/ios_screenshot_1.webp" alt="ios screenshot 1">
			<img class="ios-screenshot" src="../../../images/downloadView/ios_screenshot_2.webp" alt="ios screenshot 2">
		</div>
	</div>
	<ModalContainer modalTitle="" :showClose="false" v-if="showConfirmDialog === true">
		<div class="download-confirm-btn-container">
			<div class="download-confirm-dialog-text">接下来您将跳转到苹果应用商店(App
				Store)。由于该应用仅在海外区域上架，您需要登陆海外地区苹果账号(Apple ID)才能成功下载安装。
			</div>
			<div class="download-confirm-btn-row">
				<div class="download-cancel-btn" @click="onCancelBtnClick">{{ $t("取消") }}</div>
				<div class="download-confirm-btn" @click="onConfirmBtnClick">{{ $t("确认跳转") }}</div>
			</div>

		</div>
	</ModalContainer>
</template>
<script>
import ModalContainer from "@/components/ModalContainer.vue";
import {reportVisit} from "@/utils/methods";

export default {
	name: "IosDownloadSubView",
	components: {ModalContainer},
	data() {
		return {
			showConfirmDialog: false
		}
	},
	inject: ['isMobile', 'langStore', 'localize'],
	methods: {
		async onConfirmBtnClick() {
			this.showConfirmDialog = false
			window.open('https://apps.apple.com/us/app/%E6%98%9F%E5%85%89%E5%8A%A0%E9%80%9F/id6742536670?l=zh-Hans-CN', '_blank');
			await reportVisit('ios_download')
		},
		onCancelBtnClick() {
			this.showConfirmDialog = false
		},
		onIosDownloadClicked() {
			this.showConfirmDialog = true
		}
	}
}
</script>

<style scoped>

.download-detail-container {
	width: 100%;
	height: 90vh;
	background: rgb(246, 250, 255);
	background: linear-gradient(121deg, rgba(246, 250, 255, 1) 0%, rgba(255, 250, 245, 1) 100%);
	display: grid;
	grid-template-columns: 1fr 1fr; /* 两列，宽度相等 */
	grid-template-rows: 1fr; /* 两行，高度相等 */
	grid-gap: 1em;
	align-items: center;
	justify-content: center;
	padding: 0 20em;
	box-sizing: border-box;
}

.download-detail-container-mobile{
	width: 100%;
	min-height: 90vh;
	background: rgb(246, 250, 255);
	background: linear-gradient(121deg, rgba(246, 250, 255, 1) 0%, rgba(255, 250, 245, 1) 100%);
	display: flex;
	flex-direction: column;
	gap: 9em;
	/* grid-template-columns: 1fr; */
	/* grid-template-rows: 2fr; */
	justify-content: center;
	padding: 2em;
	box-sizing: border-box;
}

.download-detail-left-container {
	display: flex;
	flex-direction: column;
	align-items: start;
}

.download-detail-left-container-mobile{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.download-main-text {
	font-size: 2em;
	color: #E97A00;
	margin-bottom: 0.4em;
}

.download-secondary-text {
	font-size: 1.6em;
	color: #7D7C7C;
	margin-bottom: 0.4em;
	width: 100%;
	text-align: start;
}


.download-secondary-text-mobile {
	font-size: 1.4em;
	color: #7D7C7C;
	margin-bottom: 0.4em;
	text-align: center;
	width: 100%;
}

.app-download-btn {
	height: 4em;
	cursor: pointer;
	margin-top: 3em;
}

.app-download-btn:active {
	transform: scale(0.95);
}

.ios-screenshot {
	height: 36em;
}

.download-confirm-btn-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.download-confirm-dialog-text{
	color: #353332;
	padding: 0.4em 0;
	max-width: 30em;
}
.download-confirm-btn-row{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.download-detail-right-container-mobile{
	display: flex;
	flex-direction: row;
	width: 100%;
}
.ios-screenshot-mobile{
	width: 50%; /* 每个图片占据50%宽度 */
	object-fit: cover; /* 保持图片比例并填充指定区域 */
}
</style>