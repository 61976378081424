<template>
	<div class="page-header-container" v-if="!isMobile">
		<div class="page-header-title-container">
			<img class="page-header-title" src="../images/pageHeader/page_header_title.svg" alt="page header title"
			     v-if="langStore.getCurrentLanguage === 'CN'">
			<img class="page-header-title" src="../images/pageHeader/page_header_title_en.svg" alt="page header title"
			     v-else>
		</div>

		<div class="page-header-label-container">
			<div class="page-header-label-text" @click="onHeaderLabelClicked('HOME')"><span
				:class="[currentSelected === 'HOME' ? 'label-selected' : '']">{{ localize('首页') }}</span></div>
			<div class="page-header-label-text" @click="onHeaderLabelClicked('PRICE')"><span
				:class="[currentSelected === 'PRICE' ? 'label-selected' : '']">{{ localize('售价') }}</span></div>
			<div class="page-header-label-text" @click="onHeaderLabelClicked('DOWNLOAD')"><span
				:class="[currentSelected === 'DOWNLOAD' ? 'label-selected' : '']">{{ localize('下载') }}</span></div>

			<div class="page-header-label-text" @click="onHeaderLabelClicked('JOIN')">
				<img class="page-header-tg" src="../images/pageHeader/tg_header.svg" alt="tg header" @click="onJoinUs">
				<span :class="[currentSelected === 'JOIN' ? 'label-selected' : '']">{{
						localize('加入推广伙伴')
					}}</span>
			</div>

			<div class="lang-container" ref="langBtnRef" @click="onLangBtnClick">
				<img class="lang-flag-icon" :src="this.currentLangSelection.icon" alt="lang">
				<div class="lang-text">{{ this.currentLangSelection.label }}</div>
				<img class="lang-arrow" src="../images/pageHeader/lang/arrow_down.svg" alt="arrow">
			</div>

			<!-- 使用Teleport将菜单传送到body -->

		</div>
	</div>
	<div class="page-header-container-mobile" v-if="isMobile">

		<div class="page-header-title-container-mobile">
			<img class="page-header-title-mobile" src="../images/pageHeader/page_header_title.svg"
			     alt="page header title" v-if="langStore.getCurrentLanguage === 'CN'">
			<img class="page-header-title-mobile" src="../images/pageHeader/page_header_title_en.svg"
			     alt="page header title" v-else>
		</div>
		<div class="page-header-menu-container-mobile">
			<img class="menu-icon" src="../images/pageHeader/menu.svg" alt="menu" @click="onShowMenu">
		</div>

		<Teleport to="body">
			<transition
				name="slide"
				:enter-active-class="'slide-in-right'"
				:leave-active-class="'slide-out-right'"
				:appear="true">
				<div v-if="showMenu" class="menu-container">
					<div class="menu-header-mobile-container">
						<img class="menu-header-mobile" src="../images/footerComponent/footer_logo.svg" alt="logo"
						     v-if="langStore.getCurrentLanguage === 'CN'">
						<img class="menu-header-mobile" src="../images/footerComponent/footer_logo_en.svg" alt="logo"
						     v-else>
						<img class="menu-header-close-mobile" src="../images/pageHeader/close_white.svg" alt="close"
						     @click="onCloseMenu">
					</div>

					<div class="menu-item" @click="onHeaderLabelClicked('HOME')">{{ localize('首页') }}</div>
					<div class="menu-item" @click="onHeaderLabelClicked('PRICE')">{{ localize('售价') }}</div>
					<div class="menu-item" @click="onHeaderLabelClicked('DOWNLOAD')">{{ localize('下载') }}</div>
					<div class="menu-item" @click="onHeaderLabelClicked('JOIN')">{{ localize('加入推广伙伴') }}</div>
					<div class="lang-btn-wrapper-mobile">
						<div class="lang-container-mobile" ref="langBtnRef" @click="onLangBtnClick">
							<img class="lang-flag-icon-mobile" :src="this.currentLangSelection.icon" alt="lang">
							<div class="lang-text-mobile">{{ this.currentLangSelection.label }}</div>
							<img class="lang-arrow" src="../images/pageHeader/lang/arrow_down.svg" alt="arrow">
						</div>
					</div>

				</div>
			</transition>

		</Teleport>

	</div>
	<Teleport to="body">
		<Transition name="dropdown">
			<div class="lang-menu-container-bg" @click="handleOutsideClick" v-if="isLangMenuOpen">
				<div
					ref="menuRef"
					class="lang-dropdown-menu"
					:style="langMenuPosition">
					<div v-for="(item, index) in langMenuItems"
					     :key="index"
					     class="lang-menu-item"
					     @click="selectLangItem(item)">
						<img class="lang-menu-flag-icon" :src="item.icon" alt="flag">
						<div class="lang-menu-text">{{ item.label }}</div>
					</div>
				</div>
			</div>

		</Transition>
	</Teleport>
</template>
<script>
import {inject} from "vue";
import cnLangIcon from '@/images/pageHeader/lang/lang_cn.svg'
import enLangIcon from '@/images/pageHeader/lang/lang_en.svg'
import {getLanguageStore} from '@/lang/langStore'

export default {
	name: "PageHeader",
	components: {},
	inject: ['isMobile', 'langStore', 'localize'],
	data() {
		return {
			currentSelected: 'HOME',
			showMenu: false,
			isLangMenuOpen: false,
			langMenuItems: [
				{icon: cnLangIcon, label: '中文', code: 'CN'},
				{icon: enLangIcon, label: 'EN', code: 'EN'}
			],
			langMenuPosition: {
				top: '0px',
				left: '0px',
				zIndex: '1000',
				position: 'fixed'
			},
			currentLangSelection: {
				icon: null, label: null
			}
		}
	},
	// created () {
	// 	this.langStore = getLanguageStore()
	// },
	mounted() {
		this.setupCurrentLang()
		if (this.$route.name === 'mainview') {
			this.currentSelected = 'HOME'
		} else if (this.$route.name === 'priceview') {
			this.currentSelected = 'PRICE'
		} else if (this.$route.name === 'downloadView') {
			this.currentSelected = 'DOWNLOAD'
		} else if (this.$route.name === 'joinView') {
			this.currentSelected = 'JOIN'
		}
	},
	methods: {
		onHeaderLabelClicked(tag) {
			this.currentSelected = tag
			if (tag === 'PRICE') {
				this.$router.push('/priceview');
				this.showMenu = false
				return
			}
			if (tag === 'HOME') {
				this.$router.push('/main');
				this.showMenu = false
				return
			}
			if (tag === 'DOWNLOAD') {
				this.$router.push('/download');
				this.showMenu = false
				return
			}
			if (tag === 'JOIN') {
				this.$router.push('/join');
				this.showMenu = false
				return
			}

		},
		onJoinUs() {
			window.open('https://t.me/xingyunjiasu', '_blank')
		},
		onShowMenu() {
			this.showMenu = true
		},
		onCloseMenu() {
			this.showMenu = false
		},
		setupCurrentLang() {
			const currentLang = this.langStore.getCurrentLanguage.toUpperCase()
			if (currentLang === 'CN') {
				this.currentLangSelection = {
					icon: cnLangIcon,
					label: '中文'
				}
				// return '中文'
			} else {
				this.currentLangSelection = {
					icon: enLangIcon,
					label: 'EN'
				}
			}
		},
		selectLangItem(item) {
			this.currentLangSelection = {
				icon: item.icon,
				label: item.label
			}

			getLanguageStore().setLanguage(item.code)
		},
		updateMenuPosition() {

			if (!this.$refs.langBtnRef) return;

			const buttonRect = this.$refs.langBtnRef.getBoundingClientRect();

			// 设置菜单宽度与按钮相同
			this.$refs.langBtnRef.width = `${buttonRect.width}px`;

			// 菜单位置在按钮正下方
			this.langMenuPosition.top = `${buttonRect.bottom + window.scrollY}px`;
			this.langMenuPosition.left = `${buttonRect.left + window.scrollX}px`;
		},
		onLangBtnClick() {
			this.isLangMenuOpen = true
			this.updateMenuPosition();
		},
		handleOutsideClick() {
			this.isLangMenuOpen = false
		}
	}
}
</script>

<style scoped>
.label-selected {
	font-weight: bold;
}

.page-header-label-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 2em;
}

.page-header-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	background-color: #ffffff;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 1.4em 0;
	border-bottom: 2px solid #EBEBEB;
}

.page-header-container-mobile {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 100%;
	background-color: #ffffff;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 1em 0;
	border-bottom: 2px solid #EBEBEB;
}

.page-header-title {
	width: 15em;
}

.page-header-title-mobile {
	height: 2.4em;
}

.page-header-title-container {
	padding: 0 2em;
}

.page-header-title-container-mobile {
	padding: 0 1em;
}

.page-header-menu-container-mobile {
	padding: 0 1em;
}

.page-header-tg {
	width: 1em;
	padding-right: 0.2em;
	cursor: pointer;
}

.page-header-label-text {
	font-size: 1.6em;
	line-height: 1.6em;
	padding-left: 1.2em;
	cursor: pointer;
	color: #231F20;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.menu-icon {
	height: 3em;
}

.menu-container {
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	z-index: 999;
	position: fixed;
	padding: 2em;
	background-color: #37281F;
	box-sizing: border-box;
	height: 100vh;
	animation: slideInLeft 0.5s ease forwards;
}

.menu-item {
	font-size: 2em;
	color: #ffffff;
	padding: 1em 0;
	border-bottom: 1px solid #725546;
	box-sizing: border-box;
}

.lang-menu-item {
	font-size: 2em;
	color: #000000;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #ffffff;
	padding: 0.2em 0.4em;
	gap: 0.4em;
	cursor: pointer;
}

.lang-menu-item:hover {
	background-color: #d9d9d9;
}

.menu-header-mobile {
	width: 12em;
}

.menu-header-mobile-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 2em;
}

.menu-header-close-mobile {
	width: 1.8em;
}

.slide-in-right {
	animation: slideInLeft 0.5s ease forwards;
	/* 初始状态设为不可见 */
	opacity: 0;
}

@keyframes slideInLeft {
	0% {
		transform: translateX(100%);
		opacity: 1;
	}
	100% {
		transform: translateX(0);
		opacity: 1;
	}
}

.slide-out-right {
	animation: slideOutRight 0.5s ease-out forwards;
}

@keyframes slideOutRight {
	0% {
		transform: translateX(0);
		opacity: 1;
	}
	100% {
		transform: translateX(100%);
		opacity: 1;
	}
}

.lang-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: 1em;
	//background: #eeeeee;
	padding: 0.4em;
	border-radius: 0.4em;
	cursor: pointer;
}

.lang-container-mobile {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0.4em;
	border-radius: 0.4em;
	cursor: pointer;
	margin-top: 2em;
	width: fit-content;
}

.lang-btn-wrapper-mobile {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.lang-flag-icon {
	height: 1.4em;
	padding: 0 0.4em;
}

.lang-flag-icon-mobile {
	width: 2em;
	padding: 0 0.4em;
}

.lang-text {
	font-size: 1.4em;
	line-height: 1.4em;
}

.lang-text-mobile {
	font-size: 1.6em;
	line-height: 1.6em;
	color: #ffffff;
}

.lang-arrow {
	width: 1.4em;
	margin-left: 0.4em;
}

.lang-menu-flag-icon {
	width: 0.6em;
}

.lang-menu-text {
	font-size: 0.6em;
	color: #656565;
	flex: 1;
}

.lang-dropdown-menu {
	border-radius: 0.4em;
	display: flex;
	flex-direction: column;
	gap: 0.4em;
	background-color: #ffffff;
	filter: drop-shadow(0 4px 6px rgba(0, 0, 0, 0.3));
}

.lang-menu-container-bg {
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	z-index: 999;
}
</style>