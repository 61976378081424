<template>

	<template v-if="chatItem.image">
		<div v-if="chatItem?.type=== 'customer'" class="chat-container-user">
			<div class="msg-container-user">
				<span class="chat-msg-header">{{localize('我')}}</span>
				<div class="chat-img-box">
					<img class="chat-img" :src=chatItem.image alt="image">
					<div class="img-upload-mask" v-if="this.showImgMask === true && this.uploadProgress < 100">
						<div class="img-upload-progress-text">{{ this.uploadProgress }}%</div>
					</div>
				</div>
				<div v-if="chatItem?.createTimeString != null" class="chat-msg-box-time">
					{{ chatItem?.createTimeString }}
				</div>
			</div>
			<img class="avatar-img" src="../images/chartComponent/user.svg" alt="user">
		</div>
		<div v-if="chatItem?.type=== 'cs'" class="chat-container">
			<img class="avatar-img" src="../images/chartComponent/cs_avatar.svg" alt="cs avatar">
			<div class="msg-container">
				<span class="chat-msg-header">{{localize('星云加速客服')}}</span>
				<div class="chat-msg-box">{{ chatItem?.msg }}</div>
				<div v-if="chatItem?.createTimeString != null" class="chat-msg-box-time">
					{{ chatItem?.createTimeString }}
				</div>
			</div>
		</div>
	</template>

	<template v-else>
		<div v-if="chatItem?.type=== 'customer'" class="chat-container-user">
			<div class="msg-container-user">
				<span class="chat-msg-header">{{localize('我')}}</span>
				<div class="chat-msg-box">{{ chatItem?.msg }}</div>
				<div v-if="chatItem?.createTimeString != null" class="chat-msg-box-time">
					{{ chatItem?.createTimeString }}
				</div>
			</div>
			<img class="avatar-img" src="../images/chartComponent/user.svg" alt="user">
		</div>
		<div v-if="chatItem?.type=== 'cs'" class="chat-container">
			<img class="avatar-img" src="../images/chartComponent/cs_avatar.svg" alt="cs avatar">
			<div class="msg-container">
				<span class="chat-msg-header">{{localize('星云加速客服')}}</span>
				<div class="chat-msg-box">{{ chatItem?.msg }}</div>
				<div v-if="chatItem?.createTimeString != null" class="chat-msg-box-time">
					{{ chatItem?.createTimeString }}
				</div>

			</div>
		</div>
	</template>


</template>
<script>

import {getFingerprint, getServerUrl} from "@/utils/methods";
import platform from "platform";

export default {
	name: 'ChatComponent',
	data() {
		return {
			showImgMask: false,
			uploadProgress: 0,
			uploadRequest: null
		}
	},
	props: {
		chatItem: Object
	},
	inject: ['isMobile', 'langStore', 'localize'],
	async mounted() {
		if (this.chatItem.image && this.chatItem.type === 'customer' && !this.chatItem.chatId) {
			this.showImgMask = true
			await this.uploadImage(this.chatItem.imageFile)
		}
	},
	methods: {
		async uploadImage(file) {
			const formData = new FormData()
			const fp = await getFingerprint()

			formData.append('file', file)
			formData.append('fp', fp)
			const platformInfoObj = {
				name: platform.name,           // 浏览器名称
				version: platform.version,     // 浏览器版本
				layout: platform.layout,       // 浏览器渲染引擎
				os: platform.os,               // 操作系统信息
				description: platform.description, // 完整描述
				product: platform.product,     // 设备名称
				manufacturer: platform.manufacturer, // 设备制造商
				prerelease: platform.prerelease, // 预发布标识符
			}
			formData.append('platformInfo', JSON.stringify(platformInfoObj))

			// 使用XMLHttpRequest上传文件
			const xhr = new XMLHttpRequest();
			// 上传进度事件
			xhr.upload.addEventListener('progress', (event) => {
				if (event.lengthComputable) {
					this.uploadProgress = Math.round((event.loaded / event.total) * 100);

					// 更新消息列表中的上传进度
					// const msgIndex = this.msgList.findIndex(msg => msg.id === messageId);
					// if (msgIndex !== -1) {
					// 	this.$set(this.msgList[msgIndex], 'uploadProgress', progress);
					// }
				}
			});

			// 请求完成事件
			xhr.addEventListener('load', () => {
				if (xhr.status >= 200 && xhr.status < 300) {
					// 上传成功
					const response = JSON.parse(xhr.responseText);

					// 更新消息状态
					// const msgIndex = this.msgList.findIndex(msg => msg.id === messageId);
					// if (msgIndex !== -1) {
					// 	this.$set(this.msgList[msgIndex], 'isUploading', false);
					// 	this.$set(this.msgList[msgIndex], 'uploadComplete', true);
					//
					// 	// 如果服务器返回了处理后的图片URL，可以更新
					// 	if (response && response.imageUrl) {
					// 		this.$set(this.msgList[msgIndex], 'imageUrl', response.imageUrl);
					// 	}
					// }
				} else {
					// 上传失败
					// this.handleUploadError(messageId, '上传失败: ' + xhr.status);
				}
			});

			// 错误事件
			xhr.addEventListener('error', () => {
				// this.handleUploadError(messageId, '网络错误，上传失败');
			});

			// 中止事件
			xhr.addEventListener('abort', () => {
				// this.handleUploadError(messageId, '上传被取消');
			});

			const serverUrl = await getServerUrl()
			// 打开连接并发送请求
			xhr.open('POST', `${serverUrl}/website/uploadImage`, true);
			xhr.send(formData);

			// 将xhr对象存储起来，以便于可能的取消操作
			this.uploadRequest = xhr;
		}
	}
}
</script>

<style scoped>
.avatar-img {
	width: 2.4em;
}

.chat-container {
	display: flex;
	flex-direction: row;
	align-items: start;
	padding: 1em;
	gap: 0.8em;
}

.chat-container-user {
	display: flex;
	flex-direction: row;
	align-items: start;
	padding: 1em;
	gap: 0.8em;
	justify-content: flex-end; /* 添加这行 - 让子元素靠右对齐 */
}

.msg-container {
	display: flex;
	flex-direction: column;
	justify-items: start;
	flex: 1;
	align-items: start;
	gap: 0.4em;
}

.msg-container-user {
	display: flex;
	flex-direction: column;
	justify-items: start;
	flex: 1;
	align-items: end;
	gap: 0.4em;
}

.chat-msg-box {
	background: #457CBF;
	color: #ffffff;
	padding: 0.4em 0.6em;
	font-size: 1.2em;
	line-height: 1.2em;
	border-radius: 0.2em;
}

.chat-img-box {
	background: #457CBF;
	color: #ffffff;
	padding: 0.4em 0.6em;
	font-size: 1.2em;
	border-radius: 0.2em;
	max-width: 10em;
	position: relative;
}

.chat-img {
	max-width: 100%;
	max-height: 100%;
}

.chat-msg-header {
	font-size: 1em;
	color: #8c8c8c;
}

.chat-msg-box-time {
	font-size: 0.8em;
	color: #8c8c8c;
}

.img-upload-mask {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.4);
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex; /* 使用flexbox布局 */
	justify-content: center; /* 水平居中 */
	align-items: center; /* 垂直居中 */
}

.img-upload-progress-text {
	font-size: 1.2em;
	color: #ffffff;
	line-height: 1.2em;
}
</style>