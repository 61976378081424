<template>
	<div class="download-detail-container-mobile" v-if="isMobile">
		<div class="download-detail-left-container-mobile">
			<div class="download-main-text">{{localize('Windows客户端')}}</div>
			<div class="download-secondary-text-mobile">{{localize('下载Windows专用客户端')}}</div>
			<div class="download-secondary-text-mobile">{{localize('享受无限制的全球网络服务')}}</div>


			<img class="app-download-btn" src="../../../images/downloadView/win_download_btn.svg" alt="ios download btn"
			     @click="onWindowsDownloadClicked" v-if="langStore.getCurrentLanguage === 'CN'">
			<img class="app-download-btn" src="../../../images/downloadView/win_download_btn_en.svg" alt="ios download btn"
			     @click="onWindowsDownloadClicked" v-else>
		</div>

		<div class="download-detail-right-container-mobile">
			<img class="windows-screenshot" src="../../../images/downloadView/windows_screenshot_1.webp"
			     alt="windows screenshot 1">
		</div>
	</div>
	<div class="download-detail-container" v-else>
		<div class="download-detail-left-container">
			<div class="download-main-text">{{localize('Windows客户端')}}</div>
			<div class="download-secondary-text">{{localize('下载Windows专用客户端')}}</div>
			<div class="download-secondary-text">{{localize('享受无限制的全球网络服务')}}</div>

			<img class="app-download-btn" src="../../../images/downloadView/win_download_btn.svg" alt="ios download btn"
			     @click="onWindowsDownloadClicked" v-if="langStore.getCurrentLanguage === 'CN'">
			<img class="app-download-btn" src="../../../images/downloadView/win_download_btn_en.svg" alt="ios download btn"
			     @click="onWindowsDownloadClicked" v-else>
		</div>

		<div class="download-detail-right-container">
			<img class="windows-screenshot" src="../../../images/downloadView/windows_screenshot_1.webp"
			     alt="windows screenshot 1">
			<!--			<img class="ios-screenshot" src="../../../images/downloadView/ios_screenshot_2.png" alt="ios screenshot 2">-->
		</div>
	</div>
</template>
<script>
import {
	getWindowsDownloadLink,
	hideLoading,
	reportVisit,
	showLoading,
	showToast,
	startDownload,
	startWindowsDownload
} from "@/utils/methods";

export default {
	name: "WindowsDownloadSubView",
	components: {},
	data() {
		return {
			isDownloading: false
		}
	},
	inject: ['isMobile', 'langStore', 'localize'],
	methods: {
		async onWindowsDownloadClicked() {

			if (this.isDownloading) {
				return
			}

			this.isDownloading = true
			await startWindowsDownload()
			this.isDownloading = false
		},
	}
}
</script>

<style scoped>

.download-detail-container {
	width: 100%;
	height: 90vh;
	background: rgb(246, 250, 255);
	background: linear-gradient(121deg, rgba(246, 250, 255, 1) 0%, rgba(255, 250, 245, 1) 100%);
	display: grid;
	grid-template-columns: 1fr 1fr; /* 两列，宽度相等 */
	grid-template-rows: 1fr; /* 两行，高度相等 */
	grid-gap: 1em;
	align-items: center;
	justify-content: center;
	padding: 0 18em;
	box-sizing: border-box;
}

.download-detail-left-container {
	display: flex;
	flex-direction: column;
	align-items: start;
}

.download-main-text {
	font-size: 2em;
	color: #E97A00;
	margin-bottom: 0.4em;
}

.download-secondary-text {
	font-size: 1.6em;
	color: #7D7C7C;
	margin-bottom: 0.4em;
	width: 100%;
	text-align: start;
}


.download-secondary-text-mobile {
	font-size: 1.4em;
	color: #7D7C7C;
	margin-bottom: 0.4em;
	text-align: center;
	width: 100%;
}


.app-download-btn {
	height: 4em;
	cursor: pointer;
	margin-top: 3em;
}

.app-download-btn:active {
	transform: scale(0.95);
}

.windows-screenshot {
	width: 100%;
}
.download-detail-left-container-mobile{
	display: flex;
	flex-direction: column;
	align-items: center;
}
.download-detail-container-mobile{
	width: 100%;
	min-height: 90vh;
	background: rgb(246, 250, 255);
	background: linear-gradient(121deg, rgba(246, 250, 255, 1) 0%, rgba(255, 250, 245, 1) 100%);
	display: flex;
	flex-direction: column;
	gap: 9em;
	/* grid-template-columns: 1fr; */
	/* grid-template-rows: 2fr; */
	justify-content: center;
	padding: 2em;
	box-sizing: border-box;
}

.download-detail-right-container-mobile{
	display: flex;
	flex-direction: row;
	width: 100%;
}

</style>