<template>
	<div class="footer-container">
		<img class="footer-logo" src="../images/footerComponent/footer_logo.svg" alt="footer logo" v-if="langStore.getCurrentLanguage === 'CN'">
		<img class="footer-logo" src="../images/footerComponent/footer_logo_en.svg" alt="footer logo" v-else>
		<div class="footer-address-container">
			Powered by Locklayer Technologies Inc.
		</div>
		<div class="footer-copy-right">
			{{ localize("版权所有 © 2025 星云团队") }}
		</div>
	</div>
</template>
<script>
export default {
	name: "FooterComponent",
	components: {},
	inject: ['isMobile', 'langStore', 'localize'],
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped>
.footer-container {
	background-color: #352820;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	padding: 3em 0;
}

.footer-logo {
	width: 8em;
}

.footer-copy-right {
	color: #ffffff;
}
.footer-address-container{
	color: #ffffff;
	margin: 1em 0;
}
</style>