export default {
	"网页标题": "星云加速 - 专业网络加速器",
	"星云加速": "星云加速",
	"星云加速器": "星云加速器",
	"您的全球网络优化助手": "您的全球网络优化助手",
	"导航": {
		"产品特点": "产品特点",
		"应用下载": "应用下载",
		"购买会员": "购买会员",
		"用户评价": "用户评价",
		"注册登录": "注册/登录"
	},
	"标语": {
		"主标题": "新一代网址加速器，软硬安全保障，专业团队优化，为您提供顶级稳定的上网体验！",
		"立即下载": "立即下载"
	},
	"产品特点": {
		"全球优质网络节点": "全球优质网络节点",
		"快速通信覆盖服务": "快速通信覆盖服务",
		"加密数据传输": "加密数据传输，保护用户隐私",
		"智能线路优化": "智能线路优化，提升稳定连度",
		"网络连接优化": "网络连接优化，灵敏切换",
		"主流平台客户支持": "主流平台客户支持",
		"专业技术团队研发": "专业技术团队研发",
		"支持多种网络用途端": "支持多种网络用途端"
	},
	"下载版本": {
		"标题": "立即下载，开始免费试用",
		"Windows": "Windows",
		"Android": "Android",
		"iPhone": "iPhone",
		"iMac": "macOS",
		"下载": "下载",
		"版本下载": "{os}版本下载"
	},
	"会员": {
		"标题": "VIP会员",
		"标准月卡": {
			"名称": "标准月卡",
			"有效期": "30天有效期",
			"价格": "￥20",
			"客户端数量": "客户端数量：4",
			"流量": "流量：无限制"
		},
		"标准季卡": {
			"名称": "标准季卡",
			"有效期": "90天有效期",
			"价格": "￥55",
			"客户端数量": "客户端数量：4",
			"流量": "流量：无限制"
		},
		"标准半年卡": {
			"名称": "标准半年卡",
			"有效期": "180天有效期",
			"价格": "￥95",
			"客户端数量": "客户端数量：4",
			"流量": "流量：无限制"
		},
		"标准年卡": {
			"名称": "标准年卡",
			"有效期": "365天有效期",
			"价格": "￥165",
			"客户端数量": "客户端数量：4",
			"流量": "流量：无限制"
		}
	},
	"用户评价": {
		"标题": "用户评价",
		"描述": "用了2个多月，真的很稳定，很不错。"
	},
	"页脚": {
		"公司名称": "Powered by Locklayer Technologies Inc.",
		"服务地址": "Company Address: 5880 Granville Avenue, Richmond, BC, Canada",
		"服务条款": "服务条款",
		"版权声明": "版权所有 © 2025 星云加速器 团队"
	},
	"开始下载": "开始下载",
	"下载失败": "下载失败，请重试",
	"确认": "确认",
	"确认跳转": "确认跳转",
	"取消": "取消",
	"反馈": {
		"反馈原因": "反馈原因",
		"邮箱": "邮箱",
		"反馈内容": "反馈内容",
		"提交": "提交",
		"请在此输入反馈原因": "请在此输入反馈原因",
		"反馈原因不能为空": "反馈原因不能为空",
		"邮箱不能为空": "邮箱不能为空",
		"反馈内容不能为空": "反馈内容不能为空",
	}
}